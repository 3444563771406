import { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { isMobile } from 'react-device-detect';
import { BedethequeContext } from '../utils/context/bedetheque';
import { useAuthState } from '../utils/context/auth';
import { myConsoleLog } from '../libs/constantes'
import HeaderPage from '../components/headerPage';
import Album from '../components/molecules/Album';
import { handleAnchor } from '../libs/apiController';
import SerieLeftTips from '../components/molecules/SerieLeftTips';
import SerieRightNavigation from '../components/molecules/SerieRightNavigation';
import '../utils/style/serie.css';


function Serie() {
    const { bedetheque } = useContext(BedethequeContext);
    const { userPrefs } = useAuthState();
    const { serieId } = useParams()
    const [sSerie, setSserie] = useState(null)

    useEffect(() => {
        if(window.location.href.includes('#')) {
            setTimeout(() => {
                let hash = window.location.hash.substring(1);
                handleAnchor(`${hash}`)
                }, "100")
        } else {
            window.scrollTo(0, 0)
        }
        
        if(serieId && !sSerie) {
            let serieFromCtxt = bedetheque.filter((el) => el.idserie === parseInt(serieId))
            if(serieFromCtxt.length) { 
                setSserie(serieFromCtxt)
                myConsoleLog('/serie/' + serieId , serieFromCtxt)
            }
        }
    }, [bedetheque])

    if(!bedetheque || !sSerie || !serieId) return;
        
    const rightText = sSerie?.length > 1 ? sSerie?.length + ' albums' : sSerie?.length + ' album'
    
    return (
        <div>
        <HeaderPage leftText={sSerie[0]?.genre} centerText={sSerie[0]?.serie} rightText={rightText} edition={userPrefs?.editionMode} album={sSerie[0]}/>
                
        <div className={isMobile ? "spaceContainerMobile" : "spaceContainer"}>
            
            <div className='left' style={{position: 'sticky', top : '146px'}}>
                {!isMobile &&
                    <SerieLeftTips serieId={serieId} serieContent={sSerie} />
                }
            </div>

            <div className="space">
                {sSerie?.map((album, index) => ( album &&
                    <div className="albumContainer" key={`${album._id}-${index}`} id={album._id}>
                        <Album edition={userPrefs?.editionMode} reqAlbum={album} />
                    </div>
                ))}

                {isMobile &&
                    <SerieLeftTips serieId={serieId} serieContent={sSerie} isMobile={true}/>
                }
            </div>

            <div className='right' style={{position: 'sticky', top : '146px'}}>
                {sSerie?.length > 1 && !isMobile && (
                    <SerieRightNavigation serieContent={sSerie}/>
                )}
            </div>
        
        </div>
    </div>
    )
}
 
export default Serie