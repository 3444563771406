import React, { useEffect, useContext } from 'react'
import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { BedethequeContext } from '../utils/context/bedetheque'
import HeaderPage from '../components/headerPage';
import { formatCouverturePath } from '../libs/apiController'
import '../utils/style/comics.css'


const StyledAccueilLink = styled(Link)`
    text-decoration: none;
    color: #000000;    
`

function Accueil() {
    const {lastentries, wishlist} = useContext(BedethequeContext);
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    if (!lastentries || !wishlist) return;

    const nbOfLastEntries = lastentries?.length > 0 ? 12 : 0
    const nbOfWishList = wishlist?.length
    const lblTome = ' albums'

    return (
        <>
        <HeaderPage leftText={'◕‿‿◕'} centerText={'last records'} rightText={nbOfLastEntries + lblTome}/>
            
            <article className="comic">
                { lastentries?.map((album, idx) => ( album && idx < nbOfLastEntries &&
                    <StyledAccueilLink to={`serie/${album.idserie}/#${album._id}`} key={`${album._id}-${idx}`}>
                        <div className="panelFaro text-petit-bold">
                            <img alt={album.titre} src={formatCouverturePath(album)}></img>
                            <p className="text top-left">{album.serie}</p>
                            <p className="text bottom-right">
                                {!album.tome ? (album.genre) : ('#'+album?.tome + ' ' + album.titre) }
                            </p>
                        </div>
                    </StyledAccueilLink>
                )
                )}
            </article>
            
            <HeaderPage leftText={''} centerText={'Wishlist'} rightText={nbOfWishList + lblTome}/>
            
            <article className="comic">
                { wishlist?.map((album, idx) => ( album &&
                    <StyledAccueilLink to={`wishlist#${album._id}`} key={`${album._id}-${idx}`}>
                        <div className="panelFaro text-petit-bold">
                            <img alt={album.titre} src={formatCouverturePath(album)}></img>
                            <p className="text top-left">{album.serie}</p>                            
                            <p className="text bottom-right">
                                {!album.tome ? (album.genre.toString()) : ('#'+album?.tome + ' ' + album.titre) }
                            </p>
                        </div>
                    </StyledAccueilLink>
                )
                )}
            </article>
        </>
    )
}
  
export default Accueil