import { Link } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import styled, { css } from 'styled-components';
import { BedethequeContext } from '../utils/context/bedetheque'
import { STR_LNK_SEP } from '../libs/constantes';
import { updateSerie, shallowEqual } from '../libs/apiController';
import { useAuthState} from '../utils/context/auth'
import ElementInputMaker from './molecules/ElementInputMaker';
import ElementMultiselectMaker from './molecules/ElementMultiselectMaker';
import '../utils/style/headerPage.css';
import { isMobile } from 'react-device-detect';

const HeaderLink = styled(Link)`
    text-decoration: none;
    color: #000000;
`

const DynamicHeaderLink = styled(HeaderLink)`
    &:hover {
        background: linear-gradient(104deg, rgba(130, 255, 173,0) 0.9%, rgba(130, 255, 173,1.25) 2.4%, rgba(130, 255, 173,0.5) 5.8%, rgba(130, 255, 173,0.1) 93%, rgba(130, 255, 173,0.7) 96%, rgba(130, 255, 1732,0) 98%), linear-gradient(183deg, rgba(130, 255, 173,0) 0%, rgba(130, 255, 173,0.3) 7.9%, rgba(130, 255, 173,0) 15%);
        -webkit-box-decoration-break: clone;
        margin: 0;
        border-radius: 7.5px;
        text-shadow: -12px 12px 9.8px rgba(130, 255, 173,0.7), 21px -18.1px 7.3px rgba(255, 255, 255,1), -18.1px -27.3px 30px rgba(255, 255, 255,1);
        text-decoration: none;
    }
`

const CenterText = styled.span`
    /* Styles pour les écrans d'ordinateur */
    @media only screen and (min-width: 768px) {
        text-decoration: none;
        color: #000000;
        font-size: max(${props => props.thin ? "3vmin" : "3.5vmin"}, 12px);
        padding: 30px;
    }
    
    /* Styles pour les écrans mobiles */
    @media only screen and (max-width: 767px) {
        text-decoration: none;
        color: #000000;
        font-size: ${props => props.thin ? "1em" : "1.5em"};
        padding: ${props => props.thin ? "20px" : "30px"};
    }    
`

const MasterDiv = styled.div`
    ${props =>
        props.isPageSerie
        ? css`
            position: sticky;
            top: 37px;
            zIndex: '900';
            margin-bottom: ${props => props.isMobile ? "10px" : "0"};
            `
        : css`
    `}
`

const PanelDiv = styled.div`
    @media only screen and (max-width: 767px) {
        display: none;
    }
`;

function HeaderPage({leftText = "◕‿‿◕", centerText, rightText, edition, album}) {
    const { genres, reload, setReload } = useContext(BedethequeContext);
    const [albumBeforeUpdate, setAlbumBeforeUpdate] = useState({...album})
    const { user } = useAuthState();
    const [isLeftLink, setIsLeftLink] = useState(false)
    const [showTitreSerieEle, setShowTitreSerieEle] = useState(false);
    const [showGenreSerieEle, setShowGenreSerieEle] = useState(false);
    const [phraseDuJour, setPhraseDuJour] = useState(null)
    const [isPageSerie, setIsPageSerie] = useState(false)
    
    useEffect(() => {
        const pathNameWithLeftLink = ['/serie', '/bedetheque', '/stats']
        pathNameWithLeftLink.forEach((el) => {
            if(window.location.pathname.includes(el)) {
                setIsLeftLink(true)
                if(el === pathNameWithLeftLink[0])
                    setIsPageSerie(true)
                if(el === pathNameWithLeftLink[1] || el === pathNameWithLeftLink[2]) {
                    if(leftText?.length) {
                        let statsLink = leftText?.split(STR_LNK_SEP)
                        setPhraseDuJour({
                            pre: statsLink[0] ,
                            lnk: statsLink[1],
                            las: statsLink[2]
                        })
                    }
                }
            }
            return true
        })
    }, [])

    useEffect(() => {}, [reload])

    function searchParser(searchText) {
        if(searchText.includes('/')) {
            return searchText.split('/')[0]
        }
        else 
            return searchText
    }

    async function handleUpdateSerie(album) {
        if(!shallowEqual(album, albumBeforeUpdate)) {
            setAlbumBeforeUpdate({...album})
            await updateSerie(album.idserie, album, user.token)
            setReload(true)
        }
    }

    // On attend des genres dans leftText
    let genresList = (isLeftLink && isPageSerie)? leftText : null
    const centerTitleText = isPageSerie ? centerText : centerText.toUpperCase()

    return (
        <MasterDiv isMobile={isMobile} isPageSerie={isPageSerie} className="headerPage-comic" style={{'zIndex': '900'}}>
            <PanelDiv className="headerPage-panel" style={showGenreSerieEle ? {} : {overflow:'hidden'}}>
                {leftText && user && edition &&
                <div className={showGenreSerieEle ? "headerPage-text" : "headerPage-text headerPage-top-left"}>
                    <ElementMultiselectMaker
                        defaultValue={album?.genre}
                        data={genres}
                        handleChange={(e) => album.genre = e }
                        handleDoubleClick={() => setShowGenreSerieEle(true)}
                        handleBlur={() => {                                    
                            handleUpdateSerie(album)
                            setShowGenreSerieEle(false)}
                        }
                        showInputEle={showGenreSerieEle}
                        size={25}
                    />
                </div>
                }
                {leftText && isLeftLink && (!user || !edition) && 
                <p className="headerPage-text headerPage-top-left">
                    {genresList?.map((e, idx) => {
                        return(
                            <span key={`${e}${idx}`}>
                                <DynamicHeaderLink to={'../search/'+searchParser(e)}>
                                    {e}
                                </DynamicHeaderLink>
                                {idx !== genresList.length-1 && 
                                    <>,</>
                                }
                            </span>
                        )})
                    }
                    {!!phraseDuJour &&
                        <>
                        {phraseDuJour.pre}
                        <DynamicHeaderLink to={'../search/' + phraseDuJour.lnk}>
                            {phraseDuJour.lnk}
                        </DynamicHeaderLink>
                        {phraseDuJour.las}
                        </>
                    }
                </p>
                }
                {leftText && !isLeftLink && (!user || !edition) && 
                <p className="headerPage-text headerPage-top-left">{leftText}</p>
                }
            </PanelDiv>
            <div className="headerPage-panel">
                <p className="headerPage-speech-big">
                    {!isPageSerie && 
                        <CenterText>{centerTitleText}</CenterText>
                    }
                    {isPageSerie && !edition &&
                        <CenterText thin={isMobile ? centerTitleText.length > 20 : centerTitleText.length > 30}>{centerTitleText}</CenterText>
                    }
                    {isPageSerie && edition &&
                        <CenterText thin={isMobile ? centerTitleText.length > 20 : centerTitleText.length > 30}>
                            <ElementInputMaker
                                value={album.serie}
                                handleChange={(e) => {
                                    album.serie = e.target.value                                    
                                }}
                                handleDoubleClick={() => setShowTitreSerieEle(true)}
                                handleBlur={() => {                                    
                                    handleUpdateSerie(album)
                                    setShowTitreSerieEle(false)}
                                }
                                showInputEle={showTitreSerieEle}
                                size={centerTitleText.length > 10 ? centerTitleText.length - 3 : centerTitleText.length}
                            />
                        </CenterText>
                    }
                    
                </p>
            </div>
            <PanelDiv className="headerPage-panel" style={{overflow:'hidden'}}>
                {rightText &&
                    <p className="headerPage-text headerPage-bottom-right">{rightText}</p>
                }
            </PanelDiv>
        </MasterDiv>
    )
}
                
export default HeaderPage;
