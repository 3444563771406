import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { MantineProvider } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import { BedethequeContextProvider } from './utils/context/bedetheque' 
import { AuthProvider } from './utils/context/auth'
import GlobalStyle from './utils/style/globalStyle'
import TopMenu from './components/topMenu'
import Error from './pages/errors'
import Wishlist from './pages/wishlist'
import Serie from './pages/serie'
import Stats from './pages/stats'
import Bedetheque from './pages/bedetheque';
import Accueil from './pages/accueil';
import ResponsiveComics from './pages/responsiveComics';
import Search from './pages/search';
import Grab from './pages/grab';
import Dedicaces from './pages/dedicaces';
import Tomefinder from './pages/tomefinder';
import ScrollButton from './components/molecules/ScrollButton';

//import Migration from './pages/migration/migration-bigalbums';
//import Migration from './pages/migration/migration-wishlist';
//import Migration from './pages/migration/migration-auteurs';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <AuthProvider>
      <GlobalStyle />
      <BedethequeContextProvider>
        <MantineProvider withNormalizeCSS>
          <NotificationsProvider>
            <TopMenu />
            <ScrollButton />
            <Routes>
              <Route exact path="/" element={<Accueil />} />
              <Route path="/wishlist" element={<Wishlist />} />
              <Route path="/serie/:serieId" element={<Serie />} />
              <Route path="/bedetheque" element={<Bedetheque />} />
              <Route path="/search" element={<Search />} />
              <Route path="/search/:searchtxt" element={<Search />} />
              <Route path="/grab" element={<Grab />} />
              <Route path="/responsive" element={<ResponsiveComics />} />
              <Route path="/stats" element={<Stats />} />
              <Route path="/stats/:typestat" element={<Stats />} />
              <Route path="/dedicaces" element={<Dedicaces />} />
              <Route path="/tomefinder" element={<Tomefinder />} />
              {/* <Route path="/mig" element={<Migration />} /> */}
              <Route path="*" element={<Error />} />
            </Routes>
          </NotificationsProvider>
        </MantineProvider>
      </BedethequeContextProvider>
    </AuthProvider>
  </BrowserRouter>
);


