import { useAuthState } from '../../utils/context/auth'
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import '../../utils/style/serie.css';
import {updateAuteur} from '../../libs/apiController';
import ElementInputMaker from './ElementInputMaker';

const AuteurLink = styled(Link)`
    text-decoration: none;
    color: #000000;        
`;

const DynamicAuteurLink = styled(AuteurLink)`
    &:hover {
        background: linear-gradient(104deg, rgba(130, 255, 173,0) 0.9%, rgba(130, 255, 173,1.25) 2.4%, rgba(130, 255, 173,0.5) 5.8%, rgba(130, 255, 173,0.1) 93%, rgba(130, 255, 173,0.7) 96%, rgba(130, 255, 1732,0) 98%), linear-gradient(183deg, rgba(130, 255, 173,0) 0%, rgba(130, 255, 173,0.3) 7.9%, rgba(130, 255, 173,0) 15%);
        -webkit-box-decoration-break: clone;
        margin: 0;
        border-radius: 7.5px;
        text-shadow: -12px 12px 9.8px rgba(130, 255, 173,0.7), 21px -18.1px 7.3px rgba(255, 255, 255,1), -18.1px -27.3px 30px rgba(255, 255, 255,1);
        text-decoration: none;
    }
`;

function Auteur({ auteur, edition}) {
    const { user } = useAuthState();
    const [showAuteurEle, setShowAuteurEle] = useState(false);

    useEffect(() => {

    }, [edition]);
           
    async function handleUpdateAuteur(aut) {
        await updateAuteur(aut._id, aut, user.token)
    }

    return (
        <>
            {(!user || !edition) && 
                <DynamicAuteurLink to={'../search/'+auteur.nom}>
                    {auteur.nom}
                </DynamicAuteurLink>
            }
            {user && edition &&
                <ElementInputMaker
                    value={auteur.nom}
                    handleChange={(e) => {
                        auteur.nom = e.target.value                                        
                    }}
                    handleDoubleClick={() => setShowAuteurEle(true)}
                    handleBlur={() => {
                        handleUpdateAuteur(auteur)
                        setShowAuteurEle(false)
                    }}
                    showInputEle={showAuteurEle}
                    size={auteur.nom.length - 4}
                />
            }
        </>
    )
}
 
export default Auteur
