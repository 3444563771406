// import { tomefinderMock } from '../mock-data/tomefinder';
import { useEffect, useState } from 'react'
import { useAuthState } from '../utils/context/auth';
import { ENDPOINT_TOMEFINDER, myConsoleLog } from '../libs/constantes'
import HeaderPage from '../components/headerPage';
import { Link } from 'react-router-dom';
import Spinner from '../components/molecules/Spinner';
import styled from 'styled-components';
import { Switch, Group, MantineProvider } from '@mantine/core';
import { writeIgnorelist } from '../libs/apiController';
import '../utils/style/serie.css';
import '../utils/style/searchCard.css'


const ActionsText = styled.p`
    text-decoration: none;
    color: #000000;        
`;

const HoverIgnoreBtn = styled(ActionsText)`
    &:hover {
        background-image:radial-gradient(circle, yellow, orange);
        cursor: pointer;
    }
`;

const HoverRestoreBtn = styled(ActionsText)`
    &:hover {
        background-image:radial-gradient(circle, palegreen, yellowgreen);
        cursor: pointer;
    }
`;

const IdSerieLink = styled(Link)`
    text-decoration: none;
    color: #000000;
`

const HoverTitreSerieLink = styled(IdSerieLink)`
    &:hover {
        background: linear-gradient(104deg, rgba(130, 255, 173,0) 0.9%, rgba(130, 255, 173,1.25) 2.4%, rgba(130, 255, 173,0.5) 5.8%, rgba(130, 255, 173,0.1) 93%, rgba(130, 255, 173,0.7) 96%, rgba(130, 255, 1732,0) 98%), linear-gradient(183deg, rgba(130, 255, 173,0) 0%, rgba(130, 255, 173,0.3) 7.9%, rgba(130, 255, 173,0) 15%);
        -webkit-box-decoration-break: clone;
        margin: 0;
        border-radius: 7.5px;
        text-shadow: -12px 12px 9.8px rgba(130, 255, 173,0.7), 21px -18.1px 7.3px rgba(255, 255, 255,1), -18.1px -27.3px 30px rgba(255, 255, 255,1);
        text-decoration: none;
    }
`

const HoverImageScrap = styled.img`
    &:hover {
        cursor: pointer;
}
`


function Tomefinder() {
    const { user } = useAuthState();
    const [ tomefinder, setTomefinder] = useState([])
    const [ tomefinderDate, setTomefinderDate] = useState(null)
    const [loading, setLoading] = useState(false)
    const [displayIgnorelist, setDisplayIgnorelist] = useState(false)
    
    useEffect(() => {
        async function fetchTomefinder() {
            setLoading(true)
            let data = []
            await fetch(ENDPOINT_TOMEFINDER, {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${user.token}`,
            }
            })
            .then((response) => response.json())
            .then((response) => { data = response; })
            .catch((error) => { console.warn("ENDPOINT_TOMEFINDER/", error)})
            .finally(() => {
                const leftTextJour = data.date?.split('T')[0]
                const tomfinderJourHeure = data.date?.split('T')[1].split(':')
                const dateJJMMAAAA = leftTextJour.split('-')[2] +'/'+ leftTextJour.split('-')[1] +'/'+ leftTextJour.split('-')[0]
                const leftTextHeure = tomfinderJourHeure[0] + ':' + tomfinderJourHeure[1]
                const resultLeftText = `Généré le ${dateJJMMAAAA} à ${leftTextHeure}`

                setTomefinderDate(resultLeftText)
                setTomefinder(data.results)
                setLoading(false)
                myConsoleLog('ENDPOINT_TOMEFINDER/', data);
            })
        }
        fetchTomefinder()
        //setTomefinder(tomefinderMock)
    }, [])

    async function removeIgnorelist(e, item) {
        e.preventDefault()
        item.ignore = false
        await writeIgnorelist(item, user.token)
        setTimeout(() => {
            setTomefinder(tomefinder.map(el => (el.serie === item.serie ? {...el, item} : el)))
        }, 100)
    }

    function switchDisplayIgnorelist(e) {
        setTimeout(() => {
            setDisplayIgnorelist(!displayIgnorelist)
        }, 100)
        
    }

    async function addToIgnorelist(e, item) {
        e.preventDefault()
        item.ignore = true
        await writeIgnorelist(item, user.token)
        setTimeout(() => {
            setTomefinder(tomefinder.map(el => (el.serie === item.serie ? {...el, item} : el)))
        }, 100)
    }

    let listCounter = ""
    let columnMessage = ""
    try {
        listCounter = parseInt(!displayIgnorelist ? tomefinder.filter(el => !el.ignore).length : tomefinder.filter(el => el.ignore).length)
        columnMessage = listCounter + ' /' + tomefinder.length
    } catch(error) {
        console.warn('counters', error)
    }

    
    return (
        <div>
        <HeaderPage leftText={tomefinderDate ?? ''} centerText={'Tome Finder'} rightText={ loading ?  '' : columnMessage  + ' albums' }/>
            {loading &&
                <div className="pos-center">
                    <Spinner />
                </div>
            }
            
            {!loading &&
                <Group position="center" style={{paddingTop: '15px'}}>
                    <MantineProvider inherit theme={{ defaultGradient: { from: 'orange', to: 'yellow', deg: 45, }, }} >
                        <Switch size='xl' onLabel="IGNORE LIST" offLabel="IGNORE LIST" 
                            defaultChecked={displayIgnorelist}
                            onChange={switchDisplayIgnorelist}
                            color={"lime"}
                            />
                    </MantineProvider>
                </Group>
            }

            {displayIgnorelist ?
                <article className="comic">
                    {tomefinder.map((album, idx) => ( album && album.ignore &&
                        <div key={`${album.serie}-${idx}`}>
                            <div className="search-panel" >
                                <HoverImageScrap alt={album.serie} src={album.imgsrc} onClick={() => window.open(album.link)}></HoverImageScrap>
                                <p className="search-text search-top-left text-petit-bold">
                                    <HoverTitreSerieLink to={`/serie/${album.idserie}`}>
                                        {album.serie}
                                    </HoverTitreSerieLink>
                                </p>
                                <p className="search-text search-bottom-right text-petit-bold">#{album.tome}</p>
                                <HoverRestoreBtn className="search-text search-bottom-left text-petit-bold" onClick={(e) =>removeIgnorelist(e,album)}>RESTORE</HoverRestoreBtn>
                            </div>
                        </div>
                    ))}
                </article>
            :
                <article className="comic">
                    {tomefinder.map((album, idx) => ( album && !album.ignore &&
                        <div key={`${album.serie}-${idx}`}>
                            <div className="search-panel" >
                                <HoverImageScrap alt={album.serie} src={album.imgsrc} onClick={() => window.open(album.link)}></HoverImageScrap>
                                <p className="search-text search-top-left text-petit-bold">
                                    <HoverTitreSerieLink to={`/serie/${album.idserie}`}>
                                        {album.serie}
                                    </HoverTitreSerieLink>
                                </p>
                                <p className="search-text search-bottom-right text-petit-bold">#{album.tome}</p>
                                <HoverIgnoreBtn className="search-text search-bottom-left text-petit-bold" onClick={(e) =>addToIgnorelist(e,album)}>IGNORE</HoverIgnoreBtn>
                            </div>
                        </div>
                    ))}
                </article>
        }
            
        </div>
    )
}

export default Tomefinder