// Card resultante de la recherche
import { Link } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import '../../utils/style/searchCard.css'
import { formatCouverturePath } from '../../libs/apiController'

const StyledCardLink = styled(Link)`
    text-decoration: none;
    color: #000000;
`

const randomColor = ['search-bgcolor-blue','search-bgcolor-orange','search-bgcolor-green','search-bgcolor-red']

function SearchCard ({items, type, rdColor }) {

    let typeLbl;
    switch (type) {
        case 'parution':
            typeLbl = 'Parution'
            break;
        case 'serie':
            typeLbl = 'Séries'
            break;
        case 'resume':
            typeLbl = 'Résumés'
            break;                        
        default:
            typeLbl = type.charAt(0).toUpperCase() + type.slice(1) + 's'
            break;
    }

    const isItems = items.length > 0 ? (
            <div className={`search-panel ${randomColor[rdColor]}`}>
                <p className="search-speech search-fontsize-title">{typeLbl}</p>                    
            </div>
        ) : null

    function concatAuteurs(alb) {
        let arrayAuteurs = []
        if(alb.scenaristesNameList?.length > 0) {
            alb.scenaristesNameList.forEach((e) => {                
                if(arrayAuteurs.indexOf(e.nom) < 0)                
                    arrayAuteurs = [...arrayAuteurs, e.nom]
            })
        }        
        if(alb.dessinateursNameList?.length > 0) {
            alb.dessinateursNameList.forEach((e) => {
                if(arrayAuteurs.indexOf(e.nom) < 0)
                    arrayAuteurs = [...arrayAuteurs, e.nom]
            })
        }
        return arrayAuteurs.toString()
    }

    function choseText(alb, level) {
        const baseUrl = '../serie/' 
        
        if(level===0 && alb._id) return `${baseUrl}${alb.idserie}/#${alb._id}`
        if(level===0) return `${baseUrl}${alb.idserie}`

        if(level===1 && (type==='parution' || type==='remarque' || type==='editeur')) return alb.serie + ( alb.tome ? ' #'+ alb.tome : '')
        if(level===1 && type!=='parution') return alb.serie

        if(level===3 && type==='editeur') return alb.editeur
        if(level===3 && type==='album') return `#${alb.tome}. ${alb.titre}`
        if(level===3 && type==='resume') return alb.remarque?.toLowerCase().includes('one shot') ? alb.remarque : `#${alb.tome}. ${alb.titre}`
        if(level===3 && type==='serie') return (alb.genre instanceof Array ? alb.genre.toString() : alb.genre)
        if(level===3 && type==='genre') return (alb.genre instanceof Array ? alb.genre.toString() : alb.genre)
        if(level===3 && type==='parution') return alb.parution
        if(level===3 && type==='remarque') return alb.remarque
        if(level===3 && type==='auteur') return concatAuteurs(alb)
    }
    
    return (
        <>
            <article className="search-comic">
                <StyledCardLink>
                    {isItems}
                </StyledCardLink>
                {items.map((album, idx) => ( album &&
                    <StyledCardLink to={choseText(album, 0)} key={`${album.idserie}-${idx}`}>
                        <div className="search-panel text-petit-bold">
                            <img alt={choseText(album,1)} src={formatCouverturePath(album)}></img>
                            <p className="search-text search-top-left">{choseText(album,1)}</p>
                            {!isMobile &&
                            <p className="search-text search-bottom-right">{choseText(album,3)}</p>
                            }
                        </div>
                    </StyledCardLink>
                ))}
            </article>
        </>
    )
}

export default SearchCard