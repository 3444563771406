import { formatCouverturePath, handleAnchor } from '../../libs/apiController';
import '../../utils/style/serierighttips.css';

function SerieRightNavigation({ serieContent, isWishlist = false }) {
    
    const maxLengthAlbums = 16
    const widthCouvCarouselInt = serieContent?.length > maxLengthAlbums ? 5 : 7
    const heightCouvCarouselInt = Math.round(widthCouvCarouselInt * 1.41)
    const widthCouvCarousel = widthCouvCarouselInt.toString() + 'vmin'
    const heightCouvCarousel = heightCouvCarouselInt.toString() + 'vmin'

    return (
        <div>
            { serieContent?.length > 0  && 
                <div className="tipsRightContainer">
                
                    <div className='tipsRightContent'>
                            {serieContent?.map((album, index) => ( album &&
                                <div className='tipsRightImage' key={`${album._id}-${index}`}>
                                    
                                    {(!isWishlist && (album.remarque?.indexOf('Fin de cycle') > -1 || album.remarque?.indexOf('Fin du cycle') > -1 || album.remarque?.indexOf('Fin du 1er cycle') > -1 || album.remarque?.indexOf('Fin du 2ème cycle') > -1)) ? 
                                    (<span onClick={() => handleAnchor(album._id)}>
                                        <div className='infoNavSerie'>
                                            <img alt="couv" className='infoNavSerie-couv' src={formatCouverturePath(album)} style= {{'opacity': '0.6', 'height': heightCouvCarousel, 'width' : widthCouvCarousel}}/>
                                            <img alt="overlay" className='infoNavSerie-overlay' src={'/assets/findecycle.png'} style= {{'height': heightCouvCarousel, 'width' : widthCouvCarousel}} />
                                            <p className={`tipsRightImageBg tipsRightImageBL ${serieContent?.length > maxLengthAlbums ? 'text-petit-bold' : 'text-moyen-bold'}`}>
                                                {album.tome}
                                            </p>                                                
                                        </div>
                                    </span>
                                    )
                                    :
                                    (
                                    <span onClick={() => handleAnchor(album._id)}>
                                        <img src={formatCouverturePath(album)} 
                                            style= {{'height': heightCouvCarousel, 'width' : widthCouvCarousel}}
                                            alt={album.tome}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src=formatCouverturePath(album); // permet de reload l'image après le premier 404
                                            }}
                                        />
                                        <p className={`tipsRightImageBg tipsRightImageBL ${serieContent?.length > maxLengthAlbums ? 'text-petit-bold' : 'text-moyen-bold'}`}>
                                            {album.tome}
                                        </p>
                                    </span>
                                    )
                                    }
                                    
                                </div>
                            ))}
                    </div>

                </div>
            }
        </div>
    )
}

export default SerieRightNavigation;