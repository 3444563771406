import React from "react";
import Cookies from 'universal-cookie';
import {login} from "../../libs/apiUser";

const AuthStateContext = React.createContext();
const AuthDispatchContext = React.createContext();
const cookies = new Cookies();

// function reducer(currentState, newState) {
//   return { ...currentState, ...newState };
// }

function reducer(currentState, action) {
  switch (action.type) {
    case 'UPDATE_USER':
      return {
        ...currentState,
        user: action.payload,  // Mise à jour de l'utilisateur avec un nouveau token
      };
    default:
      return { ...currentState, ...action };  // Comportement par défaut
  }
}

function useAuthState() {
  const context = React.useContext(AuthStateContext);
  if (!context) throw new Error("useAuthState must be used in AuthProvider");

  return context;
}

function useAuthDispatch() {
  const context = React.useContext(AuthDispatchContext);
  if (!context) throw new Error("useAuthDispatch must be used in AuthProvider");

  return context;
}

const cookieValue = cookies.get('user') ? cookies.get('user') : null

const initialState = {
  status: (cookieValue?.status === 'resolved') ? cookieValue.status : 'idle',
  user: (cookieValue?.user) ? cookieValue.user : null,
  error: null,
  isCookie: (cookieValue?.status === 'resolved'),
  userPrefs: {
    autoSearch : (cookieValue?.userPrefs?.autoSearch === true),
    editionMode : (cookieValue?.userPrefs?.editionMode === true),
  }
};

function AuthProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {props.children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
}

async function setUserPrefs(dispatch, userPrefs) {  
  const newCookieSet = await cookies.get('user') ? cookies.get('user') : null
  if(newCookieSet?.status === 'resolved' && newCookieSet?.user) {
    const newState = {...newCookieSet, isCookie : true, userPrefs: userPrefs}
    dispatch(newState);
    cookies.set('user', JSON.stringify(newState), {path: '/', maxAge: 86400});
  }
}

async function doLogin(dispatch, user) {
  try {    
    dispatch({ status: "pending" });
    const result = await login(user);
    const resolvedStatus = {
      status: "resolved",
      user: result,
      error: null,
      isCookie: false,
      userPrefs: {
        autoSearch : false,
        editionMode : false,
      }
    }
    dispatch(resolvedStatus);
    cookies.set('user', JSON.stringify(resolvedStatus), {path: '/', maxAge: 86400});
  } catch (error) {
    dispatch({ status: "rejected", error });
  }
}

function doLogout(dispatch) {
  cookies.remove('user', {path: '/'});
  dispatch({
    status: 'idle',
    user: null,
    error: null,
    isCookie: null,
    userPrefs: null
  });
}

export { AuthProvider, useAuthState, useAuthDispatch, doLogin, doLogout, setUserPrefs };
