import { Link } from 'react-router-dom'
import { formatCouverturePath } from "../../libs/apiController"
import styled from 'styled-components'

const StyledLeftLink = styled(Link)`
    color: #000;    
`

function TipsSuiviSerie({album, suiviType, width, height, legende = false}) {

    let lblForSearch = suiviType === 'suspendue' ? 'Série suspendue' : null
    if(!!!lblForSearch) lblForSearch = suiviType === 'finie' ? 'Série finie' : null
    if(!!!lblForSearch) lblForSearch = suiviType === 'oneshot' ? 'One shot' : null
    if(!!!lblForSearch) lblForSearch = suiviType === 'findecycle' ? 'Fin du cycle' : null

    const namePng = `/assets/${suiviType}.png`

    return (
        <>
            {legende ? (
                <StyledLeftLink to={`/wishlist#${album._id}`}>
                    <div className='infoSerie tipsLeftImage'>
                        <img alt="couv" className='infoSerie-couv' src={formatCouverturePath(album)} style= {{'opacity': '0.7', 'height': height, 'width' : width}}/>
                        <img alt="overlay" className='infoSerie-overlay' src={namePng} style= {{'height': height, 'width' : width}} />                
                        <p className="tipsLeftImageBg tipsLeftImageBL text-moyen-bold">
                            {legende}
                        </p>
                    </div>
                </StyledLeftLink>                
            ) :
            (
                <StyledLeftLink to={`/search/${lblForSearch}`}>
                    <div className='infoSerie tipsLeftImage'>
                        <img alt="couv" className='infoSerie-couv' src={formatCouverturePath(album)} style= {{'opacity': '0.7', 'height': height, 'width' : width}}/>
                        <img alt="overlay" className='infoSerie-overlay' src={namePng} style= {{'height': height, 'width' : width}} />                
                    </div>
                </StyledLeftLink>
            )
            }
    </>
    )
}

export default TipsSuiviSerie;