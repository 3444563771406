import { ENDPOINT_LOGIN } from './constantes'
  
export async function login(pwd) {
    let userDetail = { password: pwd}
    return new Promise((resolve, reject) =>
        setTimeout(() => {
            fetch(ENDPOINT_LOGIN, {
                method: 'POST',
                body: JSON.stringify(userDetail),
                headers: {
                    'Content-type': 'application/json'
                }})
                .then((response) => response.json())
                .then(({token, refreshToken, user, id}) => {
                    userDetail = {
                        id: id,
                        name: user.id,
                        job: user.role,
                        token: token,
                        refreshToken: refreshToken
                    };                    
                })
                .catch((error) => {
                    return reject(error);
                })
                .finally(() => {
                    return resolve(userDetail)
                })
        }, 50)
    );
};
