import React from "react";
import { Textarea} from '@mantine/core';

function ElementTextareaMaker(props) {
  return (
    <span>
      {
        // Use JavaScript's ternary operator to specify <span>'s inner content
        props.showInputEle ? (
          <Textarea
            autosize
            variant={'default'}
            size={'sm'}
            radius={'md'}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            autoFocus
            defaultValue={props.value}
          />
        ) : (
          <span 
            style={{whiteSpace: 'pre-line'}}
            onDoubleClick={props.handleDoubleClick}
          >
            {props.value}
          </span>
        )
      }
    </span>
  );
}

export default ElementTextareaMaker;