import { useEffect, useState } from 'react'
import { Group, Input, Checkbox } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useParams } from 'react-router-dom'
import HeaderPage from '../components/headerPage'
import {ENDPOINT_SEARCH} from '../libs/constantes'
import SearchCard from '../components/molecules/SearchCard'
import { useAuthState, useAuthDispatch, setUserPrefs } from '../utils/context/auth';

const Search = () => {
    const { userPrefs } = useAuthState();
    const dispatch = useAuthDispatch();
    const [searchMode, switchSearchMode] = useState(userPrefs?.autoSearch);
    const [salbums, setAlbums] = useState([])
    const [sremarques, setRemarques] = useState([])
    const [sauteurs, setAuteurs] = useState([])
    const [sediteurs, setEditeurs] = useState([])
    const [sgenres, setGenres] = useState([])
    const [sparutions, setParutions] = useState([])
    const [sseries, setSeries] = useState([])
    const [sresumes, setResumes] = useState([])

    const [searchText, setSearchText] = useState(null)
    const [searchTextHtml, setSearchTextHtml] = useState(searchText)
    const [resultsTextHtml, setResultsTextHtml] = useState(null)
    const [inputText, setInputText] = useState(false)

    const {searchtxt} = useParams()

    useEffect(() => {
        async function fetchSearch(lQuery) {
            if(!lQuery) return
            let totalRecords = 0;
            await fetch(ENDPOINT_SEARCH + lQuery)
                .then((response) => response.json())
                .then((response) => {                    
                    let data = []
                    data = response
                    setSearchTextHtml(lQuery)
                    setAlbums(data._album)
                    setAuteurs(data._auteur)
                    setEditeurs(data._editeur)
                    setGenres(data._genre)
                    setParutions(data._parution)
                    setSeries(data._serie)
                    setRemarques(data._remarque)
                    setResumes(data._resume)
                    totalRecords = data._total
                })
                .catch((error) => {
                    console.warn(ENDPOINT_SEARCH + " : An error occured")                    
                })
                .finally(() => {                    
                    setResultsTextHtml(totalRecords)
                })
        };
        
        let searchParam = null
        inputText?.length > 2 && (searchParam = inputText);
        !searchParam && !!searchtxt && (searchParam = searchtxt);
        fetchSearch(searchParam);

    }, [inputText, searchtxt])
  
    useEffect(() => {
        if(userPrefs) {
            userPrefs.autoSearch = searchMode
            setUserPrefs(dispatch, userPrefs)
        }
    }, [searchMode])

    function initStates() {
        setAlbums(null)
        setAuteurs(null)
        setEditeurs(null)
        setGenres(null)
        setParutions(null)
        setRemarques(null)
        setSeries(null)
        setAuteurs(null)
        setResumes(null)
        setSearchTextHtml(null)
        setResultsTextHtml(null)
    }

    const handleChange = (e) => {
        setSearchText(e.target.value)
        if(searchMode) {
            if(e.target.value?.length > 2){
                setInputText(e.target.value)
             }
        }        
    };

    const handleKeyPress = (e) => {
        if(!searchMode && e.key === 'Enter' && searchText?.length > 2){
            handleChange(e)
            setInputText(searchText)
            e.target.value = null
        } else if(searchMode) {
            handleChange(e)
            if(e.target.value.length === 0) initStates()
            setInputText(searchText)
            if(e.key === 'Enter') {
                e.target.value = null
                initStates()
            }
        }
    };

    const showNotifAutoSearch = () => {
        showNotification({
            autoClose: 1000,
            title: 'Mode de recherche',
            message: `${!searchMode ? '✅ Automatique' : '↩️ Validation'}`,
            styles: (theme) => ({
                root: {
                  backgroundColor: theme.white,
                  borderColor: theme.colors.orange[6],
                  '&::before': { backgroundColor: theme.white },
                },
                title: { color: theme.blue },
                description: { color: theme.colors.orange[6] },
                closeButton: {
                  color: theme.black,
                  '&:hover': { backgroundColor: theme.colors.orange[6] },
                },
              }),
        })
    }

    return (
        <div>
            <HeaderPage leftText={!!resultsTextHtml ? (resultsTextHtml > 1 ? `${resultsTextHtml} résultats` : `${resultsTextHtml} résultat`) : null} centerText={'Recherche'} rightText={searchTextHtml}/>

            <div style={{paddingTop: '15px'}}>
                <Group position="center">
                    <Input 
                        radius="lg"
                        size="xl"
                        placeholder="titre, auteur, éditeur, genre, parution, remarque..."
                        onChange={handleChange}
                        onKeyDown={handleKeyPress}
                        className="search-input-text"
                        autoFocus
                    />
                    <Checkbox 
                        defaultChecked={searchMode}
                        radius="lg"
                        size="xl"
                        color={'lime'}
                        onChange={() => {
                            switchSearchMode(!searchMode);                        
                            showNotifAutoSearch()
                        }}
                    />
                </Group>

                {sseries?.length    > 0 && <SearchCard items={sseries} type='serie' rdColor={0}/>}
                {salbums?.length    > 0 && <SearchCard items={salbums} type='album' rdColor={1}/>}
                {sgenres?.length    > 0 && <SearchCard items={sgenres} type='genre' rdColor={2}/>}
                {sauteurs?.length   > 0 && <SearchCard items={sauteurs} type='auteur' rdColor={3}/>}
                {sediteurs?.length  > 0 && <SearchCard items={sediteurs} type='editeur' rdColor={1}/>}
                {sremarques?.length > 0 && <SearchCard items={sremarques} type='remarque' rdColor={2}/>}
                {sparutions?.length > 0 && <SearchCard items={sparutions} type='parution' rdColor={1}/>}
                {sresumes?.length > 0 && <SearchCard items={sresumes} type='resume' rdColor={1}/>}
            </div>
        </div>
    )
}

export default Search;