import { isMobile } from 'react-device-detect';
import { Rating, Checkbox, Button, Input, Spoiler } from '@mantine/core';
import { useAuthState } from '../../utils/context/auth'
import { BedethequeContext } from '../../utils/context/bedetheque';
import { useRef, useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ElementInputMaker from './ElementInputMaker';
import ElementTextareaMaker from './ElementTextareaMaker';
import Auteur from './Auteur';
import { deleteAlbum, updateBigAlbum, updateSerie, updateCouverture, formatCouverturePath, formatDedicacePath, shallowEqual } from '../../libs/apiController';
import '../../utils/style/serie.css';


const AlbumLink = styled(Link)`
    text-decoration: none;
    color: #000000;        
`;

const DynamicAlbumLink = styled(AlbumLink)`
    &:hover {
        background: linear-gradient(104deg, rgba(130, 255, 173,0) 0.9%, rgba(130, 255, 173,1.25) 2.4%, rgba(130, 255, 173,0.5) 5.8%, rgba(130, 255, 173,0.1) 93%, rgba(130, 255, 173,0.7) 96%, rgba(130, 255, 1732,0) 98%), linear-gradient(183deg, rgba(130, 255, 173,0) 0%, rgba(130, 255, 173,0.3) 7.9%, rgba(130, 255, 173,0) 15%);
        -webkit-box-decoration-break: clone;
        margin: 0;
        border-radius: 7.5px;
        text-shadow: -12px 12px 9.8px rgba(130, 255, 173,0.7), 21px -18.1px 7.3px rgba(255, 255, 255,1), -18.1px -27.3px 30px rgba(255, 255, 255,1);
        text-decoration: none;
    }
`;

function Album({ reqPage, reqAlbum, edition}) {
    const { reload ,setReload, bedetheque } = useContext(BedethequeContext);
    const { user } = useAuthState();
    const imageRef=useRef();
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
      ]);

    const [albumBeforeUpdate, setAlbumBeforeUpdate] = useState({...reqAlbum})
    const [showRemarqueEle, setShowRemarqueEle] = useState(false);
    const [showResumeEle, setShowResumeEle] = useState(false);
    const [showAlbumTitreEle, setShowAlbumTitreEle] = useState(false);
    const [showAlbumTomeEle, setShowAlbumTomeEle] = useState(false);
    const [showSerieTitreEle, setShowSerieTitreEle] = useState(false);
    const [showEditeurEle, setShowEditeurEle] = useState(false);
    const [showParutionEle, setShowParutionEle] = useState(false);
    const [showCouvertureEle, setShowCouvertureEle] = useState(false);
    
    const [chkCouleur, setChkCouleur] = useState(reqAlbum.couleur);
    const [chkTirageLimite, setChkTirageLimite] = useState(reqAlbum.tiragelimite);
    const [chkIntegrale, setChkIntegrale] = useState(reqAlbum.integrale);

    const initDedicaceState = reqPage === 'dedicaces' ? true : false
    const [isShowDedicace, setIsShowDedicace] = useState(initDedicaceState)

    const isNewSerie = bedetheque.filter((el) => el.idserie === parseInt(reqAlbum.idserie)).length <= 0

    useEffect(() => {
        const handleWindowResize = () => {
          setWindowSize([window.innerWidth, window.innerHeight]);
        };
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      });

    useEffect(() => {}, [reload])

    const formatIntituleAlbum = () => {
        if(!reqAlbum) return ''
        let preIntitule = (reqPage === 'wishlist'  || reqPage === 'dedicaces')? reqAlbum.serie + ' ' : '';
        // Si integrale 
        if(reqAlbum.integrale) return `${preIntitule}${reqAlbum.tome ? ' #'+reqAlbum.tome : ''} ${reqAlbum.titre ? reqAlbum.titre : ''}`
        // Si serie + tome + titre
        if(reqAlbum.titre?.length && reqAlbum.tome) return `${preIntitule}#${reqAlbum.tome} ${reqAlbum.titre}`
        // Si serie + tome
        if(reqAlbum.tome) return `${preIntitule}#${reqAlbum.tome}`
        // Si serie + titre
        if(reqAlbum.titre) return `${preIntitule}${reqAlbum.titre}`

        return reqAlbum.serie
    }

    const hydrate = {
        formatIntituleAlbum : formatIntituleAlbum(),
        id           : reqAlbum._id,
        idserie      : reqAlbum.idserie,
        serie        : reqAlbum.serie,
        genre        : reqAlbum.genre,
        titre        : user && edition && (!reqAlbum.titre || reqAlbum.titre?.length < 2) ? '💭' : reqAlbum.titre,
        tome         : user && edition && !reqAlbum.tome ? '💭' : reqAlbum.tome,
        couverture   : formatCouverturePath(reqAlbum),
        dedicace     : reqAlbum.dedicace ? formatDedicacePath(reqAlbum) : false,
        dessinateurs : reqAlbum.dessinateursNameList,
        scenaristes  : reqAlbum.scenaristesNameList,
        parution     : user && edition && !reqAlbum.parution ? '💭' : reqAlbum.parution,
        editeur      : user && edition && (!reqAlbum.editeur || reqAlbum.editeur?.length < 2) ? '💭' : reqAlbum.editeur,
        note         : reqAlbum.note,
        resume       : user && edition && (!reqAlbum.resume || reqAlbum.resume?.length < 2) ? '💭' : reqAlbum.resume,
        remarque     : user && edition && (!reqAlbum.remarque || reqAlbum.remarque?.length < 2) ? '💭' : reqAlbum.remarque,
        couleur      : reqAlbum.couleur,
        integrale    : reqAlbum.integrale,
        tiragelimite : reqAlbum.tiragelimite,
        oneshot      : reqAlbum.remarque?.indexOf('One shot') > -1 || reqAlbum.remarque?.indexOf('one shot') > -1 || reqAlbum.remarque?.indexOf('One Shot') > -1
    }        

    function isScenaristeAndDessinateur() {
        let result = hydrate.scenaristes.map(el => {
            if (hydrate.dessinateurs.filter(e => e.nom === el.nom).length > 0) {
                return el._id
            }
            return null
        })
        return result.filter((e) => e !== null)
    }
    async function handleUpdateAlbum(album) {
        if(!shallowEqual(album, albumBeforeUpdate)) {
            setAlbumBeforeUpdate({...album})
            await updateBigAlbum(album._id, album, user.token)
            setReload(true)
        }
    }
    async function handleUpdateSerie(album) {
        if(!shallowEqual(album, albumBeforeUpdate)) {
            setAlbumBeforeUpdate({...album})
            await updateSerie(album.idserie, album, user.token)
            setReload(true)
        }
    }
    async function handleDeleteAlbum(album) {
        await deleteAlbum(album._id, album, user.token)
        setReload(true)
        window.location.reload()
    }
    async function handleUpdateCouverture(url) {
            const regex = /(https?:\/\/.*\.(?:jpeg|jpg))/i;
            if (url.match(regex)) await updateCouverture(reqAlbum._id, url, user.token)
            setShowCouvertureEle(false)
    }

    function remarquesParser(hydrate) {
        let remarques = hydrate.remarque ? hydrate.remarque : null
        if(!!remarques) {
            remarques = remarques + (!!hydrate.integrale ? ', Intégrale' : '')
        } else {
            remarques = !!hydrate.integrale ?'Intégrale' : ''
        }
        return '📌 ' + remarques
    }    
    
    function genreSearchParser(genre) {
        const genreString = genre instanceof Array ? genre.join(', ') : genre                               
        if(genreString.includes('/')) 
            return genre.split('/')[0]        
        else 
            return genre
    }

    function switchCouvDedicace() {
        const cSetBool = isShowDedicace ? false : true
        imageRef.current.src = isShowDedicace ? hydrate.couverture : hydrate.dedicace
        setIsShowDedicace(cSetBool)
    }

    return (
        <>
            <div className="image">
                <div className="serie-comic">
                    <div className="serie-panel">
                        <img
                            src={!isShowDedicace ? hydrate.couverture : hydrate.dedicace}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src=hydrate.couverture; // permet de reload l'image après le premier 404
                            }}
                            alt={hydrate.titre}
                            ref={imageRef} 
                            onDoubleClick={() => setShowCouvertureEle(edition && true)}
                        />
                        { hydrate.dedicace &&
                        <p className="serie-text serie-top-right dedicace" onClick={() => {switchCouvDedicace()}} style={!isShowDedicace ? {backgroundColor: 'crimson', color: 'white'} :  {color: 'black'} }>
                            Dédicace
                        </p>
                        }

                        
                        {reqPage !== 'wishlist' && !isShowDedicace && !isMobile && (
                            <div className="serie-text serie-bottom-left">
                                { !user || !edition ? (
                                    <Rating size={ (windowSize[0] < 1400 || windowSize[1] < 800) ? 'xs' : 'md'} defaultValue={hydrate.note} readOnly/>
                                ) :(
                                    <Rating size={ (windowSize[0] < 1400 || windowSize[1] < 800) ? 'xs' : 'md'} defaultValue={hydrate.note} onChange={(e) => {
                                        reqAlbum.note = e;
                                        handleUpdateAlbum(reqAlbum)                                        
                                    }}/>
                                )}
                            </div>
                        )}
                        {reqPage !== 'wishlist' && isMobile && (
                            <div className="serie-text serie-bottom-left">
                                {hydrate.note}⭐
                            </div>
                        )}



                        <p className="serie-text serie-bottom-right">
                            {user && edition && !isShowDedicace &&
                            <>
                                <ElementInputMaker
                                    value={reqAlbum.editeur}
                                    handleChange={(e) => {
                                        reqAlbum.editeur = e.target.value                                    
                                    }}
                                    handleDoubleClick={() => setShowEditeurEle(true)}
                                    handleBlur={() => {
                                        handleUpdateAlbum(reqAlbum)
                                        setShowEditeurEle(false)}
                                    }
                                    showInputEle={showEditeurEle}
                                    size={15}
                                />&nbsp;(
                                <ElementInputMaker
                                    value={reqAlbum.parution}
                                    handleChange={(e) =>{
                                        reqAlbum.parution = e.target.value                                    
                                    }}
                                    handleDoubleClick={() => setShowParutionEle(true)}
                                    handleBlur={() => {
                                        handleUpdateAlbum(reqAlbum)
                                        setShowParutionEle(false)
                                    }}
                                    showInputEle={showParutionEle}
                                    size={5}
                                />)
                            </>
                            }
                             {(!user || !edition) && !isShowDedicace &&
                            <>
                                <DynamicAlbumLink to={`../search/${hydrate.editeur}`}>{hydrate.editeur}</DynamicAlbumLink>&nbsp;
                                <DynamicAlbumLink to={`../search/${hydrate.parution}`}>({hydrate.parution})</DynamicAlbumLink>
                            </>
                             }
                        </p>
                        {hydrate.couleur ? 
                            (null) :
                            (<p className='serie-text serie-top-left'>⚫</p>)
                        }
                    </div>
                </div>
            </div>
            
            <div className="detail">
                <div className="serie-comic">
                    
                    <div className="serie-panel serie-bg-blue">
                        
                        <p className="serie-text serie-top-left">
                            {!edition && reqPage === 'wishlist' && hydrate.formatIntituleAlbum.split('#')[0] &&
                            <>
                                {!isNewSerie &&
                                    <DynamicAlbumLink to={`/serie/${hydrate.idserie}`}>
                                        {hydrate.formatIntituleAlbum.split('#')[0]?.toString()}
                                    </DynamicAlbumLink>
                                }
                                {isNewSerie && <>{hydrate.formatIntituleAlbum.split('#')[0]?.toString()}</>}
                                {!hydrate.oneshot && <>#</>}
                                {hydrate.formatIntituleAlbum.split('#')[1]?.toString()}
                            </>
                            }

                            {!edition && reqPage !== 'wishlist' && 
                                hydrate.formatIntituleAlbum
                            }
                            
                            {user && edition && 
                            <>
                                {reqPage === 'wishlist' && hydrate.tome &&
                                <>
                                    <ElementInputMaker
                                        value={hydrate.serie}
                                        handleChange={(e) => {
                                            reqAlbum.serie = e.target.value                                
                                        }}
                                        handleDoubleClick={() => setShowSerieTitreEle(true)}
                                        handleBlur={() => {
                                            handleUpdateSerie(reqAlbum)
                                            setShowSerieTitreEle(false)
                                        }}
                                        showInputEle={showSerieTitreEle}
                                        size={hydrate.serie?.length}
                                    />
                                    &nbsp;
                                </>
                                }
                                <>
                                    #
                                    <ElementInputMaker
                                        value={hydrate.tome}
                                        handleChange={(e) => {
                                            reqAlbum.tome = e.target.value                                    
                                        }}
                                        handleDoubleClick={() => setShowAlbumTomeEle(true)}
                                        handleBlur={() => {
                                            handleUpdateAlbum(reqAlbum)
                                            setShowAlbumTomeEle(false)}
                                        }
                                        showInputEle={showAlbumTomeEle}
                                        size={hydrate.tome?.length}
                                    />&nbsp;
                                    <ElementInputMaker
                                        value={hydrate.titre}
                                        handleChange={(e) =>{
                                            reqAlbum.titre = e.target.value                                    
                                        }}
                                        handleDoubleClick={() => setShowAlbumTitreEle(true)}
                                        handleBlur={() => {
                                            setShowAlbumTitreEle(false)
                                            handleUpdateAlbum(reqAlbum)
                                        }}
                                        showInputEle={showAlbumTitreEle}
                                        size={hydrate.titre?.length}
                                    />      
                                </>
                            </>
                            }
                            {user && edition && !hydrate.tome && 
                            <>
                                #
                                <ElementInputMaker
                                    value={'💭'}
                                    handleChange={(e) => {
                                        reqAlbum.tome = e.target.value                                    
                                    }}
                                    handleDoubleClick={() => setShowAlbumTomeEle(true)}
                                    handleBlur={() => {
                                        handleUpdateAlbum(reqAlbum)
                                        setShowAlbumTomeEle(false)}
                                    }
                                    showInputEle={showAlbumTomeEle}
                                    size={3}
                                />&nbsp;
                                <ElementInputMaker
                                    value={hydrate.serie}
                                    handleChange={(e) => {
                                        reqAlbum.serie = e.target.value                                
                                    }}
                                    handleDoubleClick={() => setShowSerieTitreEle(true)}
                                    handleBlur={() => {
                                        handleUpdateSerie(reqAlbum)
                                        setShowSerieTitreEle(false)
                                    }}
                                    showInputEle={showSerieTitreEle}
                                />
                            </>
                            }                           
                        </p>                                                
                        
                        <div className="auteurs">
                            {hydrate.scenaristes.length > 0 && hydrate.scenaristes.map((sce, idx) => 
                                    <div className="serie-speech-arrow-none" key={`${sce._id}+${idx}`}>{isScenaristeAndDessinateur().includes(sce._id) ? '🖊️🖌️' : '🖊️'}
                                        <Auteur 
                                            auteur={sce}
                                            edition={edition}
                                        />
                                    </div>
                            )}
                            {hydrate.dessinateurs.length > 0 && hydrate.dessinateurs.map((des, idx) => 
                                !isScenaristeAndDessinateur().includes(des._id) && 
                                        <div className="serie-speech-arrow-none" key={`${des.id}+${idx}`}>🖌️
                                            <Auteur 
                                                auteur={des}
                                                edition={edition}
                                            />
                                        </div>
                            )}
                        </div>
                        
                        {(!edition && (hydrate.remarque?.length > 1 || !!hydrate.integrale)) && 
                        <p className="serie-text serie-bottom-left">
                            {remarquesParser(hydrate)}
                        </p>
                        }

                        {user && edition &&
                        <div className="serie-text serie-bottom-left">
                            📌&nbsp;
                            <ElementInputMaker
                                value={hydrate.remarque}
                                handleChange={(e) => {
                                    reqAlbum.remarque = e.target.value                                        
                                }}
                                handleDoubleClick={() => setShowRemarqueEle(true)}
                                handleBlur={() => {
                                    handleUpdateAlbum(reqAlbum)
                                    setShowRemarqueEle(false)
                                }}
                                showInputEle={showRemarqueEle}
                                size={hydrate.remarque?.length > 1 ? hydrate.remarque.length - 4 : 10}
                            />
                        </div>
                        }                        
                        
                        {reqPage === 'wishlist' &&
                            <p className="serie-text serie-bottom-right">                                
                                {hydrate.genre instanceof Array && hydrate.genre?.map((e, idx) => {
                                    return(
                                        <span key={`${e}${idx}`}>
                                            <DynamicAlbumLink to={'../search/'+genreSearchParser(e)}>
                                                {e}
                                            </DynamicAlbumLink>
                                            {idx !== hydrate.genre.length-1 && 
                                                <>, </>
                                            }
                                        </span>
                                )})}   
                            </p>
                        }
                        
                    </div>
                </div>
                
                {user && edition &&
                <div>
                    <div className="serie-comic">
                        {isMobile &&
                            <div className={"serie-panel serie-bg-blue checkbox-wrapper mobile"}>
                                <label>
                                    <input type="checkbox" 
                                        checked={chkCouleur} 
                                        onChange={(event) => {
                                            setChkCouleur(event.currentTarget.checked)
                                            reqAlbum.couleur = event.currentTarget.checked
                                            handleUpdateAlbum(reqAlbum)
                                    }}/>
                                    <span>Couleur</span>
                                </label>
                                <label>
                                    <input type="checkbox" 
                                        checked={chkTirageLimite} 
                                        onChange={(event) => {
                                            setChkTirageLimite(event.currentTarget.checked)
                                            reqAlbum.tiragelimite = event.currentTarget.checked
                                            handleUpdateAlbum(reqAlbum)
                                    }} />
                                    <span>Tirage limité</span>
                                </label>
                                <label>
                                    <input type="checkbox" 
                                        checked={chkIntegrale} 
                                        onChange={(event) => {
                                            setChkIntegrale(event.currentTarget.checked)
                                            reqAlbum.integrale = event.currentTarget.checked
                                            handleUpdateAlbum(reqAlbum)
                                    }} />
                                    <span>Intégrale</span>
                                </label>
                                
                            </div>
                        }
                        {!isMobile &&
                            <div className={"serie-panel serie-bg-blue"}>
                                <Checkbox label="Couleur" className={'checkbox-album' } size={"sm"} radius="xl"
                                    checked={chkCouleur} 
                                    onChange={(event) => {
                                        setChkCouleur(event.currentTarget.checked)
                                        reqAlbum.couleur = event.currentTarget.checked
                                        handleUpdateAlbum(reqAlbum)
                                }} />
                                <Checkbox label="Tirage limité" className={'checkbox-album' } size={"sm"}  radius="xl" 
                                    checked={chkTirageLimite}  
                                    onChange={(event) => {
                                        setChkTirageLimite(event.currentTarget.checked)
                                        reqAlbum.tiragelimite = event.currentTarget.checked
                                        handleUpdateAlbum(reqAlbum)
                                }} />
                                <Checkbox label="Intégrale" className={'checkbox-album' } size={"sm"}  radius="xl" 
                                    checked={chkIntegrale} 
                                    onChange={(event) => {
                                        setChkIntegrale(event.currentTarget.checked)
                                        reqAlbum.integrale = event.currentTarget.checked
                                        handleUpdateAlbum(reqAlbum)
                                }} />
                            </div>
                        }
                    </div>

                    {showCouvertureEle && 
                        <div className="serie-comic">
                            <div className="serie-panel">
                                <Input type={'text'} size={'xs'} placeholder={'url de la nouvelle couverture'} autoFocus
                                    onBlur={ (e) => handleUpdateCouverture(e.target.value) }
                                >
                                </Input>
                            </div>
                        </div>
                    }
                </div>
                }
                
                {( hydrate.resume?.length >1 || (user && edition)) &&
                <>
                    <div className="serie-comic">
                        <div className="serie-panel serie-bg-green">
                            <p className="serie-text serie-top-left">Résumé</p>
                            <div className='resume-text'>
                                {(!user || !edition) && 
                                    <Spoiler maxHeight={ isMobile ? 130 : (windowSize[0] < 2000 || windowSize[1] < 600) ? 340 : 520}  showLabel="Lire plus" hideLabel="Replier">
                                        <span style={{whiteSpace: 'pre-line'}}>{hydrate.resume}</span>
                                    </Spoiler>
                                }
                                {user && edition &&
                                <ElementTextareaMaker
                                    value={hydrate.resume}
                                    handleChange={(e) => {
                                        reqAlbum.resume = e.target.value                                        
                                    }}
                                    handleDoubleClick={() => setShowResumeEle(true)}
                                    handleBlur={() => {
                                        handleUpdateAlbum(reqAlbum)
                                        setShowResumeEle(false)
                                    }}
                                    showInputEle={showResumeEle}
                                />
                                }                                
                            </div>
                        </div>                                        
                    </div>
        
                    {user && edition &&
                        <div className="admin-menu">                        
                            { reqPage === 'wishlist' && 
                            <Button className='button-admin' size={'md'} color={'teal'} onClick={() => {
                                reqAlbum.wishlist = false
                                handleUpdateAlbum(reqAlbum).finally(() => window.location.href = '/')
                            }}>
                                ACHETE</Button> 
                            }                        
                            <Button className='button-admin-delete' size={'xs'} color={'red'} onClick={() => {
                                if (window.confirm('Supprimer cet album ?')) handleDeleteAlbum(reqAlbum) 
                            }}>
                                DELETE</Button>
                        </div>
                    }
                </>
            }
            </div>
        </>
    )
}
 
export default Album
