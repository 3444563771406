import { useContext, useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { BedethequeContext } from '../utils/context/bedetheque'
import { useAuthState } from '../utils/context/auth'
import HeaderPage from '../components/headerPage'
import Album from '../components/molecules/Album'
import SerieRightNavigation from '../components/molecules/SerieRightNavigation'
import { handleAnchor } from '../libs/apiController'
import '../utils/style/serie.css'


function Wishlist() {
    const { wishlist } = useContext(BedethequeContext);
    const { userPrefs } = useAuthState();
    
    useEffect(() => {
        if(window.location.href.includes('#')) {
            setTimeout(() => {
                let hash = window.location.hash.substring(1);
                handleAnchor(`${hash}`)
              }, "100")
            
        } else {
            window.scrollTo(0, 0)
        }
    }, [])

    if (!wishlist) return;
    
    return (
        <>
            <HeaderPage centerText={'Wishlist'} rightText={ wishlist?.length + ' albums' }/>
                    
            <div className={isMobile ? "spaceContainerMobile" : "spaceContainer"}> 
            
                <div className='left'>
                    
                </div>

                <div className="space">
                    {wishlist?.map((album, index) => ( album &&
                        <div className="albumContainer" key={`${album._id}-${index}`} id={album._id}>
                            <Album edition={userPrefs?.editionMode} reqPage={'wishlist'} reqAlbum={album} />
                        </div>
                            
                    ))}
                </div>
                
                <div className='right' style={{position: 'sticky', top : '30px'}}>                    
                    {!isMobile &&                        
                        <SerieRightNavigation serieContent={wishlist} isWishlist={true}/>
                    }
                </div>

            </div>
        </>
    )
}
 
export default Wishlist