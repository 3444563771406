import { useEffect, useState } from 'react'
import { useAuthState } from '../utils/context/auth';
import { ENDPOINT_DEDICACES, myConsoleLog } from '../libs/constantes'
import HeaderPage from '../components/headerPage';
import Album from '../components/molecules/Album';
import { handleAnchor } from '../libs/apiController'
import '../utils/style/serie.css';
import { isMobile } from 'react-device-detect';

function Dedicaces() {
    const { userPrefs } = useAuthState();
    const [ dedicaces, setDedicaces] = useState([])
    
    useEffect(() => {
        async function fetchDedicaces() {
            let data = []
            await fetch(ENDPOINT_DEDICACES)
            .then((response) => response.json())
            .then((response) => { data = response; })
            .catch((error) => { console.warn("ENDPOINT_DEDICACES/", error)})
            .finally(() => {
                setDedicaces(data)
                myConsoleLog('ENDPOINT_DEDICACES/', data);
            })
        }

        fetchDedicaces()

        if(window.location.href.includes('#')) {
            setTimeout(() => {
                let hash = window.location.hash.substring(1);
                handleAnchor(`${hash}`)
              }, "100")
            
        } else {
            window.scrollTo(0, 0)
        }
    }, [])

    if (!dedicaces) return;

    return (
        <>
        <HeaderPage centerText={'Albums dédicacés'} rightText={ dedicaces?.length + ' albums' }/>
                
            <div className={isMobile ? "spaceContainerMobile" : "spaceContainer"}> 
                <div className="space">
                    {dedicaces?.map((album, index) => ( album &&
                        <div className="albumContainer" key={`${album._id}-${index}`} id={album._id}>
                            <Album edition={userPrefs?.editionMode} reqAlbum={album} reqPage={'dedicaces'} />
                        </div>
                            
                    ))}
                </div>
            </div>
        </>
    )
}

export default Dedicaces