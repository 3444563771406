import '../utils/style/responsiveComics.css'

function ResponsiveComics() {

    return (
        <>
        <article className="comic">
            <div className="panel">
                <p className="text top-left">Suddenly...</p>
                <p className="text bottom-right">...something amazing happened</p>
            </div>
            <div className="panel">
                <p className="text top-left">Try resizing...</p>
                <p className="text bottom-right">...it's responsive</p>
            </div>
            <div className="panel">
                <p className="speech">A speech bubble</p>
            </div>
            <div className="panel"></div>
            <div className="panel"></div>
            <div className="panel"></div>
            <div className="panel"></div>
            <div className="panel"></div>
            <div className="panel">
                <p className="text bottom-right">THE END</p>
            </div>
        </article>
        </>
    )
}

export default ResponsiveComics