import { useState, useEffect, useContext } from 'react'
import { isMobile } from 'react-device-detect';
import { Button, Group, Input, MultiSelect, Autocomplete, MantineProvider, Textarea} from '@mantine/core';
import { BedethequeContext } from '../utils/context/bedetheque'
import HeaderPage from '../components/headerPage';
import { useAuthState } from '../utils/context/auth'
import { Checkbox  } from '@mantine/core';
import { grabAlbum, createAlbumInBedetheque } from '../libs/apiController';
import { myConsoleLog } from '../libs/constantes'
import Spinner from "../components/molecules/Spinner";
import '../utils/style/grab.css';

const paddingStyle = {
    margin : '10px'
}

function Grab(login) {
    const {genres, auteurs, bedethequeAndWishlistBySerie} = useContext(BedethequeContext);
    const { user } = useAuthState();
    
    const [urlToGrab, setUrlToGrab] = useState(null)
    const [albumGrab, setAlbumGrab] = useState(null)
    const [loading, setLoading] = useState(false)

    const [chkCouleur, setChkCouleur] = useState(true);
    const [chkTirageLimite, setChkTirageLimite] = useState(false);
    const [chkIntegrale, setChkIntegrale] = useState(false);
    
    const [serieTrouve, setSerieTrouve] = useState(false);
    const [tomeTrouve, setTomeTrouve] = useState(false);

    const [grabSerie, setGrabSerie] = useState();
    const [grabIdSerie, setGrabIdSerie] = useState();
    const [grabTome, setGrabTome] = useState();
    const [grabTitre, setGrabTitre] = useState();
    const [grabGenre, setGrabGenre] = useState([]);
    const [grabEditeur, setGrabEditeur] = useState();
    const [grabParution, setGrabParution] = useState();    
    const [grabScenaristes, setGrabScenaristes] = useState();
    const [grabScenaristesAcreer, setGrabScenaristesAcreer] = useState();
    const [grabDessinateurs, setGrabDessinateurs] = useState();
    const [grabDessinateursAcreer, setGrabDessinateursAcreer] = useState();
    const [grabRemarques, setGrabRemarques] = useState();
    const [grabResume, setGrabResume] = useState();
    const [grabCouverture, setGrabCouverture] = useState();
    const [autoCompleteAuteurs, setAutoCompleteAuteurs] = useState();

    const constBedethequeComUrl = 'https://www.bedetheque.com';
    
    async function resetStates() {
        setAlbumGrab(null)
        setGrabSerie(null)
        setGrabIdSerie(null)
        setGrabTome(null)
        setGrabTitre(null)
        setGrabGenre(null)
        setGrabEditeur(null)
        setGrabParution(null)
        setGrabScenaristes(null)
        setGrabScenaristesAcreer(null)
        setGrabDessinateurs(null)
        setGrabDessinateursAcreer(null)
        setGrabRemarques(null)
        setGrabResume(null)
        setGrabCouverture(null)
    }

    useEffect(() => {
        if(!!albumGrab) {
            setSerieTrouve(albumGrab.trouve?.serie?.trouve)
            setTomeTrouve(albumGrab.trouve?.album?.trouve)
            setChkCouleur(true)

            setGrabSerie(albumGrab.serie)
            setGrabTome(albumGrab.tome)
            setGrabTitre(albumGrab.album)

            if(albumGrab.trouve?.serie?.trouve) {
                setGrabIdSerie(albumGrab.trouve.serie.item?.idserie)
                setGrabGenre(albumGrab.trouve.serie.item?.genre)
            } else {
                // on a le numero de serie à créer via le module grab du serveur
                setGrabIdSerie(albumGrab.trouve.idserie)
            }
            setGrabParution(albumGrab.parution)
            setGrabEditeur(albumGrab.editeur)
            setGrabResume(albumGrab.resume)
            setGrabRemarques(albumGrab.remarque)
            setGrabCouverture(albumGrab.couverture)

            let letSce = []
            let letDes = []
            let letSceAcreer = []
            let letDesAcreer = []
            if(albumGrab.trouve?.scenariste?.trouve) {
                letSce.push(albumGrab.trouve?.scenariste?.item)
            } else {
                albumGrab.scenaristes.forEach((sce, idx) => letSceAcreer.push({id: idx, nom : sce}))
            }

            if(albumGrab.trouve?.dessinateur?.trouve) {
                letDes.push(albumGrab.trouve?.dessinateur?.item)
            } else {
                if(albumGrab.dessinateurs.length > 0) {
                    albumGrab.dessinateurs.forEach((des, idx) => letDesAcreer.push({id: idx, nom : des}))
                } else {
                    if(albumGrab.trouve?.scenariste?.trouve) {
                        letDes.push(albumGrab.trouve?.scenariste?.item)
                    } else {
                        albumGrab.scenaristes.forEach((sce, idx) => letDesAcreer.push({id: idx, nom : sce}))
                    }
                }                
            }

            setGrabScenaristes(letSce);
            setGrabDessinateurs(letDes);
            setGrabScenaristesAcreer(letSceAcreer);
            setGrabDessinateursAcreer(letDesAcreer);

            const data = auteurs.map((item) => ({ ...item, value: item.nom }));
            setAutoCompleteAuteurs(data);
        }
        
    }, [albumGrab, auteurs])

    async function handleSetGrabGenre(e) {
        setGrabGenre(e)
    }
   
    async function handleGenre(event) {
        let arrGenre = event.target.value.split(',').map((item) => item.trim())
        setGrabGenre(arrGenre)
    }

    async function handleclick() {
        if(!urlToGrab || !urlToGrab.startsWith(constBedethequeComUrl)) return
        setLoading(true)
        myConsoleLog(urlToGrab)
        await grabAlbum(urlToGrab, user.token)
        .then((response) => {
            setAlbumGrab(response)
        })
        .finally(() => {
            setLoading(false)
        })
    }

    const handleChange = (e) => {
        setUrlToGrab(e.target.value)
    };

    const handleKeyPress = (e) => {
        if(e.key === 'Enter' && urlToGrab.startsWith(constBedethequeComUrl)){
            handleclick()
        }
    };

    async function handleClickBedetheque(isWishlist) {
        if(!grabGenre?.length || !grabSerie?.length) return

        let jsonBody = {
            tome: grabTome,
            titre: grabTitre,
            parution: grabParution,
            scenaristes: grabScenaristes,
            dessinateurs:grabDessinateurs,
            scenaristesAcreer: grabScenaristesAcreer,
            dessinateursAcreer:grabDessinateursAcreer,
            editeur: grabEditeur,
            couverture: grabCouverture,
            idserie: grabIdSerie,
            serie: grabSerie,
            genre: grabGenre,
            remarque: grabRemarques,
            resume: grabResume,
            couleur: +chkCouleur,
            integrale: +chkIntegrale,
            tiragelimite: +chkTirageLimite,
            note: 0,
            wishlist: +isWishlist
        }

        await createAlbumInBedetheque(jsonBody, user.token)
        .then((response) => {
            myConsoleLog(response)
        })
        .finally(() => {
            window.location.href = '/';
        })
    }

    /* TODO :
         - si dessinateur == scenariste : modifier icone en face du nom
    */


    const handleChangeInputSerie = (value) => {
        let foundIndex = bedethequeAndWishlistBySerie.findIndex(x => x.serie === value);
        let trouve = bedethequeAndWishlistBySerie[foundIndex]
        setGrabSerie(value.length ? value : " ")
        setSerieTrouve(false)
        if(trouve) {
            setGrabSerie(trouve.serie)
            setGrabGenre(trouve.genre)
            setGrabIdSerie(trouve.idserie)
            setSerieTrouve(true)
        } else {
            if(albumGrab.trouve?.serie?.trouve) {
                setGrabIdSerie(albumGrab.trouve.serie.item?.idserie)
                setGrabGenre(albumGrab.trouve.serie.item?.genre)
            } else {
                setGrabIdSerie(albumGrab.trouve.idserie)
                setGrabGenre("")
            }
        }
    }
          
    const handleChangeInputScenariste = (newValue, sce) => {
        let letScenaristes = grabScenaristesAcreer;
        let foundIndex = grabScenaristesAcreer.findIndex(x => x.id === sce.id);
        let trouve = auteurs.filter((e) => e.nom === newValue)

        if(trouve.length > 0) {
            letScenaristes.splice(foundIndex, 1);
            setGrabScenaristesAcreer(letScenaristes)
            setGrabScenaristes(trouve)
        } else {
            letScenaristes[foundIndex] = {id: sce.id, nom : newValue}
            setGrabScenaristesAcreer(letScenaristes)
        }
    }

    const handleChangeInputDessinateur = (newValue, des) => {
        let letDessinateurs = grabDessinateursAcreer;
        let foundIndex = grabDessinateursAcreer.findIndex(x => x.id === des.id);
        let trouve = auteurs.filter((e) => e.nom === newValue)
        
        if(trouve.length > 0) {
            letDessinateurs.splice(foundIndex, 1);
            setGrabDessinateursAcreer(letDessinateurs)
            setGrabDessinateurs(trouve)
        } else {
            letDessinateurs[foundIndex] = {id: des.id, nom : newValue}
            setGrabDessinateursAcreer(letDessinateurs)
        }
    }

    const handleChangeInputTomeNum = (evt) => {
        setGrabTome(evt.target.value)
    }

    const handleChangeInputTomeTitre = (evt) => {
        setGrabTitre(evt.target.value)
    }

    return (
        <>
        <HeaderPage leftText={''} centerText={'Grab album'} rightText={''}/>        
            
        {loading &&
            <div className="pos-center">
                <Spinner />
            </div>
        }

        {!albumGrab && !loading &&
        <>
        <Group position="center" style={{'paddingTop': '15px'}}>
            <Input 
                radius="lg"
                size="xl"
                placeholder="bedetheque album url"
                onChange={handleChange}
                onKeyDown={handleKeyPress}
                className="search-input-text"
                autoFocus
            />
            <Button color={'lime'} radius={'xl'} size="md" onClick={ handleclick }>GO</Button>
        </Group>
        <br/>
        
        <Group position="center">
            <MantineProvider inherit theme={{ defaultGradient: { from: 'white', to: 'grey', deg: 45, }, }} >
                <Button variant="gradient" radius={'xl'} size="md" style={{color: 'grey'}} onClick={ () => window.open(constBedethequeComUrl, '_blank', 'noreferrer') }>{constBedethequeComUrl.split('https://www.')[1]}</Button>
            </MantineProvider>
        </Group>
        </>
        }
       
        {!!albumGrab && 
        <>
        <div className='buttonContainer' style={{paddingTop: '15px'}}>
            <div className="buttonCreate">
                
            <Group position="center">
                <MantineProvider
                    inherit
                    theme={{
                    defaultGradient: {
                        from: '#69DB7C',
                        to: '#74B816',
                        deg: 45,
                    },                        
                    }}
                >
                    <Button variant="gradient" onClick={() => handleClickBedetheque(false)}>BEDETHEQUE</Button>
                </MantineProvider>
                <Button onClick={() => resetStates()}>X</Button>
                <MantineProvider
                    inherit
                    theme={{
                    defaultGradient: {
                        from: '#F783AC',
                        to: '#D6336C',
                        deg: 45,
                    },
                    }}
                >
                    <Button variant="gradient" onClick={() => handleClickBedetheque(true)}>&nbsp;&nbsp;&nbsp;WISHLIST&nbsp;&nbsp;&nbsp;</Button>
                </MantineProvider>
            
            </Group>
            </div>
        </div>
        <div className={isMobile ? "spaceContainerMobile" : "spaceContainer"}>
            <div className="space">                
            
                <div className="albumContainer">
                    
                    <div className='image'>
                        <article className="serie-comic">
                            <div className="serie-panel">
                                <img src={grabCouverture} alt={grabSerie}></img>
                                <p className="serie-text serie-bottom-right">
                                    <input value={grabEditeur} size={12} />
                                    &nbsp;(<input value={grabParution} size={5} />)
                                </p>                        
                            </div>
                        </article>
                    </div>
                    
                    <div className="detail">
                        
                        <div className="serie-comic">
                            <div className="serie-panel serie-bg-blue">
                                <div style={paddingStyle}>
                                    <MultiSelect
                                        searchable
                                        mt="sm"
                                        value={grabGenre}
                                        data={genres}
                                        placeholder="Genre"
                                        onChange={(e) => { handleSetGrabGenre(e)}}
                                    />
                                    {bedethequeAndWishlistBySerie && grabSerie &&
                                        <Autocomplete
                                            icon={'📚'}
                                            defaultValue={grabSerie}
                                            onChange={(e) => handleChangeInputSerie(e)}
                                            data={bedethequeAndWishlistBySerie}
                                            rightSection={serieTrouve ? '✅' : ''}
                                        />
                                    }
                                    
                                    <Input icon={'#️⃣'} size="sm" placeholder="Numéro de tome" value={grabTome} onChange={(e) => handleChangeInputTomeNum(e)} rightSection={tomeTrouve && '✅'}/>
                                    <Input icon={'📙'} size="sm" placeholder="Titre du tome" value={grabTitre} onChange={(e) => handleChangeInputTomeTitre(e)} />

                                    {grabScenaristes?.map((sce, idx) => {
                                        return (
                                            <span key={`${sce._id}+${idx}`}>
                                                <Input icon={'🖊️'} size="sm" value={sce.nom} readOnly rightSection={'✅'} />                                                
                                            </span>
                                        )
                                    })}
                                    {grabScenaristesAcreer?.map((sce, idx) => {
                                        return (
                                            <span key={`${sce.nom}+${idx}`}>
                                                <Autocomplete
                                                    icon={'🖊️'}
                                                    defaultValue={sce.nom}
                                                    onChange={(e) => handleChangeInputScenariste(e, sce)}
                                                    data={autoCompleteAuteurs}
                                                />
                                            </span>
                                        )
                                    })}
                                    {grabDessinateurs?.map((des, idx) => {
                                        return (
                                            <span key={`${des._id}+${idx}`}>
                                                <Input icon={'🖌️'} size="sm" value={des.nom} readOnly rightSection={'✅'}/>
                                            </span>
                                        )
                                    })}
                                    {grabDessinateursAcreer?.map((des, idx) => {
                                        return (
                                            <span key={`${des.nom}+${idx}`}>
                                                <Autocomplete
                                                    icon={'🖌️'}
                                                    value={des.nom}
                                                    onChange={(e) => handleChangeInputDessinateur(e, des)}
                                                    data={autoCompleteAuteurs}
                                                />
                                            </span>
                                        )
                                    })}
                                    
                                    <Input icon={'🔖'} size="sm" value={grabGenre?.toString()} onChange={(e) => handleGenre(e)}/>
                                    <Input icon={'📌'} size="sm" onChange={(e) => setGrabRemarques(e.target.value)}/>
                                </div>

                            </div>
                        </div>
                        
                        <div>
                            <article className="serie-comic">
                                <div className="serie-panel serie-bg-blue">
                                    <Checkbox label="Couleur" className='checkbox-album' size="sm" radius="xl"
                                        checked={chkCouleur} 
                                        onChange={(event) => {
                                            setChkCouleur(event.currentTarget.checked)
                                            }} />
                                    <Checkbox label="Tirage limité" className='checkbox-album' size="sm" radius="xl" 
                                        checked={chkTirageLimite}  
                                        onChange={(event) => {
                                            setChkTirageLimite(event.currentTarget.checked)
                                            }} />
                                    <Checkbox label="Intégrale" className='checkbox-album' size="sm" radius="xl" 
                                        checked={chkIntegrale} 
                                        onChange={(event) => {
                                            setChkIntegrale(event.currentTarget.checked)
                                            }} />
                                </div>
                            </article>
                        </div>

                        {albumGrab.resume?.length >1 &&
                        <div className="resume">
                            <div className="serie-comic">
                                <div className="serie-panel serie-bg-green">
                                    <p className="serie-text serie-top-left">Résumé</p>
                                    <div className='resume-text'>
                                        <Textarea
                                            autosize
                                            variant={'default'}
                                            size={'sm'}
                                            radius={'md'}
                                            value={grabResume}
                                            onChange={(e) => setGrabResume(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                    
                    
                    
                </div>
                
            </div>

        </div>
        </>
        }
        </>
    )
}

export default Grab;