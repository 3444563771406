import { STR_LNK_SEP } from '../libs/constantes';


export function getPhrasedujour(stats) {
    if(!stats) return []

    let phrasedujour = 'prout!';

    let lotoTypeStats = ['GENRE', 'GENRE', 'GENRE', 'GENRE', 'GENRE', 'GENRE', 'AUTEUR', 'AUTEUR', 'AUTEUR', 'EDITEUR', 'EDITEUR', 'EDITEUR', 'AUTEUR', 'AUTEUR', 'AUTEUR', 'EDITEUR', 'EDITEUR', 'EDITEUR', 'PARUTION', 'NOTE', 'DIVERS']
    const randLoto      = Math.floor(Math.random()*lotoTypeStats.length)
    const lotoTirage    = lotoTypeStats[randLoto]
    const filteredStats = stats.filter((e) => e.TYPESTATS === lotoTirage)
    const randLotoStats = Math.floor(Math.random()*filteredStats[0]?.STATS.length)

    const phrase = {
        type: filteredStats[0]?.TYPESTATS,
        stat: filteredStats[0]?.STATS[randLotoStats]
    }
    
    switch (phrase.type) {
        case 'EDITEUR':
            phrasedujour = `L'éditeur ${STR_LNK_SEP}${phrase.stat._id}${STR_LNK_SEP} est représenté ${phrase.stat.count} fois `
            break;
        case 'AUTEUR':
            phrasedujour = `On retrouve l'auteur ${STR_LNK_SEP}${phrase.stat._id}${STR_LNK_SEP} ${phrase.stat.count} fois `
            break;
        case 'GENRE':
            phrasedujour = `Il y a ${phrase.stat.count} fois le genre ${STR_LNK_SEP}${phrase.stat._id}${STR_LNK_SEP} `
            break;
        case 'DIVERS':
            phrasedujour = `La bédéthèque regroupe ${phrase.stat.count} ${phrase.stat._id} `
            break;
        case 'NOTE':
            phrasedujour = `Il y a ${phrase.stat.count} album${phrase.stat.count>1 ? 's' : '' } noté${phrase.stat.count>1 ? 's' : '' } ${phrase.stat._id} étoiles `
            break;
        case 'PARUTION':
            phrasedujour = `${phrase.stat.count} album${phrase.stat.count>1 ? 's' : '' } édité${phrase.stat.count>1 ? 's' : '' } en ${STR_LNK_SEP}${phrase.stat._id}${STR_LNK_SEP} `
            break;
        default:
            phrasedujour = `Pas de phrase du jour de cas non prévu`
            break;
    }

    return phrasedujour;
}