import { BedethequeContext } from '../../utils/context/bedetheque';
import { useEffect, useState, useContext } from 'react';
import TipsSuiviSerie from '../atomics/tipsSuiviSerie';
import '../../utils/style/serielefttips.css';


function SerieLeftTips({ serieId, serieContent = null, isMobile = false }) {
    const { wishlist } = useContext(BedethequeContext);
    const [sWish, setSwish] = useState(null)
    const [sTomeFinder, setStomeFinder] = useState(null)
    const [sTipsSuiviSerie, setStipsSuiviSerie] = useState(null)

    const widthCouvCarouselInt = isMobile ? 16 : 8
    const heightCouvCarouselInt = Math.round(widthCouvCarouselInt * 1.41)
    const widthCouvCarousel = widthCouvCarouselInt.toString() + 'vmin'
    const heightCouvCarousel = heightCouvCarouselInt.toString() + 'vmin'

    useEffect(() => {

        async function getTipsSuiviSerie() {
            const isSerieFinie = serieContent.filter( el => el.remarque?.indexOf('Série finie') > -1)
            const isCycleTermine = serieContent.filter( el => el.remarque?.indexOf('Fin de cycle') > -1 || el.remarque?.indexOf('Fin du cycle') > -1 || el.remarque?.indexOf('Fin du 1er cycle') > -1 || el.remarque?.indexOf('Fin du 2ème cycle') > -1)
            const isOneShot = serieContent.filter( el => el.remarque?.indexOf('One shot') > -1 || el.remarque?.indexOf('one shot') > -1 || el.remarque?.indexOf('One Shot') > -1)
            const isSerieSuspendue = serieContent.filter( el => el.remarque?.indexOf('Série suspendue') > -1 || el.remarque?.indexOf('Série abandonnée') > -1)
            
            const res = { serieFinie: isSerieFinie, cycleTermine: isCycleTermine, oneShot: isOneShot, serieSuspendue: isSerieSuspendue, countResults: isSerieFinie.length + isOneShot.length + isSerieSuspendue.length }
            setStipsSuiviSerie(res)
            return res
        }


        async function getSerieFromWishlist() {
            const els = wishlist.filter((el) => el.idserie === parseInt(serieId)).sort(
                function(a, b) {
                    var keyA = a.tome;
                    var keyB = b.tome;
                    if (keyA < keyB) return -1;
                    if (keyA > keyB) return 1;
                    return 0;
                  }
            )
            setSwish(els)
        }
        async function getSerieFromTomeFinder() {
            fetch(process.env.REACT_APP_NODE_URI + "tomefinder/" + serieId)
                    .then((response)    => response.json())
                    .then((response)    => { 
                        const res = response?.code === 200 ? response : null ;
                        setStomeFinder(res)
                    })
                    .catch((error)      => { console.warn('serieFromTomeFinder', error) })
        }

        setTimeout(() => {
            getSerieFromWishlist()
            getSerieFromTomeFinder()
            if(serieContent?.length > 0) getTipsSuiviSerie()
        }, 200);
        
        
        
    }, [serieId, wishlist, serieContent])

    return (
        <div>
            <div className="tipsLeftContainer">
                <div>
                    { (sTipsSuiviSerie?.countResults > 0 || sWish?.length > 0 || sTomeFinder?.results?.length > 0) && 
                    <div className='tipsLeftTitle'>
                        <div className='infoSerie' style={{marginLeft: '0', marginBottom: '5px'}}>
                            <img alt="couv" className='infoSerie-couv' src={'/assets/reminders.png'} style= {{'height': '40%', 'width' : '40%'}}/>
                        </div>
                    </div>
                    }
                    
                    <div className='tipsLeftContent'>
                        {sTipsSuiviSerie && sTipsSuiviSerie.serieFinie?.length > 0 && 
                            <TipsSuiviSerie album={sTipsSuiviSerie.serieFinie[0]} suiviType={'finie'} width={widthCouvCarousel} height={heightCouvCarousel}/>
                        }
                        {sTipsSuiviSerie && sTipsSuiviSerie.serieSuspendue?.length > 0 && 
                            <TipsSuiviSerie album={sTipsSuiviSerie.serieSuspendue[0]} suiviType={'suspendue'} width={widthCouvCarousel} height={heightCouvCarousel}/>
                        }
                        {sTipsSuiviSerie && sTipsSuiviSerie.oneShot?.length > 0 && 
                            <TipsSuiviSerie album={sTipsSuiviSerie.oneShot[0]} suiviType={'oneshot'} width={widthCouvCarousel} height={heightCouvCarousel}/>
                        }

                        <div className='tipsLeftBreak'></div>

                        {sWish?.length > 0 && sWish?.map((wish, idx) => { return (
                                <TipsSuiviSerie key={'wish-'+idx} album={wish} suiviType={'wishlist'} legende={wish.tome} width={widthCouvCarousel} height={heightCouvCarousel}/>
                            )})
                        }
                        
                        <div className='tipsLeftBreak'></div>

                        {sTomeFinder?.results?.length > 0 && sTomeFinder?.results?.map((tf, idx) => { return (
                            <div key={'tf-'+idx} className="tipsLeftImage infoSerie" style={{cursor: 'pointer'}} onClick={() => window.open(tf.link)}>
                                <img 
                                    className='infoSerie-couv'
                                    style= {{'height': heightCouvCarousel, 'width' : widthCouvCarousel}}
                                    alt={tf.tome} 
                                    src={tf.imgsrc} 
                                    />
                                <img alt="overlay" className='infoSerie-overlay' src={'/assets/tomefinder.png'} style= {{'height': heightCouvCarousel, 'width' : widthCouvCarousel}} />                
                                <p className="tipsLeftImageBg tipsLeftImageBL text-moyen-bold">
                                    {tf.tome}
                                </p>
                            </div>
                            )})
                        }

                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default SerieLeftTips;
