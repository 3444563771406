import { useContext, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect';
import { BedethequeContext } from '../utils/context/bedetheque'
import HeaderPage from '../components/headerPage';
import styled from 'styled-components';
import { Link } from "react-router-dom"
import { Button } from '@mantine/core';
import { getPhrasedujour } from '../libs/utilitaires.jsx'
import { formatCouverturePath } from '../libs/apiController'
import '../utils/style/comics.css'

const StyledBedethequeLink = styled(Link)`
    text-decoration: none;
    color: #000000;
`

const Bedetheque = () => {
    const { bedetheque, bedethequeBySerie, stats } = useContext(BedethequeContext)
    const [sSerie, setSserie] = useState(null)
    const [filtre, setFiltre] = useState(true)
    const [phraseDuJour, setPhraseDuJour] = useState(null)
        
    useEffect(() => {
        window.scrollTo(0, 0);
        if(!filtre)
            setSserie(bedetheque)
        else
            setSserie(bedethequeBySerie)
        
        setPhraseDuJour(getPhrasedujour(stats))
      }, [filtre, bedetheque, bedethequeBySerie])
    

    if (!bedetheque || !sSerie) return;

    const isSerieOrAlbum = filtre ? ' séries' : ' albums'

    return (
        <div>
            <HeaderPage leftText={phraseDuJour} centerText={'bédéthèque'} rightText={sSerie?.length + isSerieOrAlbum }/>

            <div className='filtreContainer'>
                <div className='filtre'>
                    <Button color='pink' radius="xl" size="xs" compact uppercase onClick={() => setFiltre(filtre ? false : true)}>{!filtre ? 'albums' : 'séries'}</Button>
                </div>
            </div>

            <div className="comic">                
                { sSerie?.map((album, idx) => 
                    (album &&
                        <StyledBedethequeLink to={filtre ? `../serie/${album.idserie}` : `../serie/${album.idserie}/#${album._id}`} key={`${album.idserie}-${idx}`}>                                
                            <div className="panelFaro text-petit-bold">
                                <img alt={album.serie} src={formatCouverturePath(album)}></img>
                                <p className="text top-left">{album.serie}</p>
                                {!isMobile &&
                                <p className="text bottom-right">
                                    {filtre || !album.tome ? (album.genre.toString()) : ('#'+album?.tome + ' ' + album.titre) }
                                </p>
                                }
                            </div>
                        </StyledBedethequeLink>
                    ) 
                )}

            </div>

        </div>
    )
}

export default Bedetheque;