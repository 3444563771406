import { createGlobalStyle } from 'styled-components'

const StyledGlobalStyle = createGlobalStyle`
img {
    vertical-align: 0 !important;
}
body {
    background-color: rgb(39, 36, 36) !important;
}

/* Styles pour les écrans d'ordinateur */
@media only screen and (min-width: 768px) {
    .text-petit-bold {
        font-size: max(1.4vmin, 12px);
        font-weight: bold;    
    }
    
    .text-moyen-bold {
        font-size: max(1.8vmin, 12px);
        font-weight: bold;
    }
}

/* Styles pour les écrans mobiles */
@media only screen and (max-width: 767px) {
    .text-petit-bold {
        font-size: 9px;
    }
    
    .text-moyen-bold {
        font-size: 10px;
    }
}

`

function GlobalStyle() {
    return <StyledGlobalStyle />
}

export default GlobalStyle
