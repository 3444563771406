import React from "react";
import { MultiSelect } from "@mantine/core";

// Create an ElementMaker component
function ElementMultiselectMaker(props) {
  return (
    <span>
      {
        props.showInputEle ? (
          <MultiSelect
              searchable
              mt="sm"
              data={props.data}
              defaultValue={props.defaultValue}
              placeholder="Genre"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
          />          
        ) : (
          <span
            onDoubleClick={props.handleDoubleClick}
            style={{
              display: "inline-block",
            }}
          >
            {props.defaultValue.toString()}
          </span>
        )
      }
    </span>
  );
}

export default ElementMultiselectMaker;