import { useContext, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { BedethequeContext } from '../utils/context/bedetheque'
import HeaderPage from '../components/headerPage';
import { getPhrasedujour } from '../libs/utilitaires.jsx'
import styled from 'styled-components';
import '../utils/style/stats.css';

import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { type } from '@testing-library/user-event/dist/type/index.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

const H2styled = styled.h2`
    margin-top: 40px;
    color: #fff;
`

const DIVstyled = styled.div`
    max-width: 800px;
    margin: 0 auto;
`

const Stats = () => {
    const { typestat } = useParams()
    const { stats } = useContext(BedethequeContext)
    const [phraseDuJour, setPhraseDuJour] = useState(false);

    useEffect(() => {
        const nouvellePhrase = getPhrasedujour(stats);
        setPhraseDuJour(nouvellePhrase);
    }, [stats]);
    
    if (!stats) return;

    // Fonction pour garder les 10 premiers éléments et cumuler les autres
    const getTop10WithOthers = (data) => {
        const sortedData = data.sort((a, b) => b.count - a.count); // Trier par ordre décroissant
        const top10 = sortedData.slice(0, 10); // Prendre les 10 premiers
        const others = sortedData.slice(10); // Prendre les autres

        // if (others.length > 0) {
        //     const othersCount = others.reduce((sum, item) => sum + item.count, 0); // Calculer la somme des autres
        //     top10.push({ _id: 'Autres', count: othersCount }); // Ajouter l'entrée "Autres"
        // }

        return top10;
    };    

    const labelForTypeStats = (typeStats) => {
        switch (typeStats) {
            case 'DIVERS':
                return 'Panorama de la collection';
            case 'EDITEUR':
                return 'Top des Éditeurs';
            case 'GENRE':
                return 'Top des Genres';
            case 'PARUTION':
                return 'Chronologie des Albums';
            case 'AUTEUR':
                return 'Top des Auteurs';
            case 'NOTE':
                return 'Évaluation des Albums'
            default:
                return typeStats;
        }
    }
    // Fonction pour créer un jeu de données de graphique
    const createChartData = (data, label) => {
        const colors = [
            '#f44b70',
            '#98e86e',
            '#ffba00',
            '#00c7fd',
            '#8777cc',            
            '#f44b70',
            '#98e86e',
            '#ffba00',
        ];

        return {
            labels: data.map(item => item._id),
            datasets: [{
                label: '',
                data: data.map(item => item.count),
                // backgroundColor: '#2ec6f8',
                backgroundColor: colors.slice(0, data.length),
                borderColor: '#fff',
                // borderColor: colors.slice(0, data.length).map(color => color.replace('0.6', '1')),
                borderWidth: 1,
            }]
        };
    };

    // Configurations des options du graphique
    const generalOptions = {
        scales: {
            x: {
                ticks: {
                    color: '#98f58a', // Couleur des textes des abscisses
                    font: {
                        size: 12, // Taille de la police (facultatif)
                        family: 'Arial', // Police d'écriture (facultatif)
                        weight: 'normal', // Poids de la police (facultatif) normal, bold
                    }
                },
            },
            y: {
                ticks: {
                    color: '#ffcc00', // Couleur des textes des ordonnées
                    font: {
                        size: 12, // Taille de la police (facultatif)
                        family: 'Arial', // Police d'écriture (facultatif)
                        weight: 'normal', // Poids de la police (facultatif)
                    }
                },
                grid: {
                    color: 'rgba(128, 0, 128, 0.3)', // Couleur des lignes de la grille associée aux ordonnées (facultatif)
                },
            },
        },
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: false,
                text: 'Statistiques des BD',
            },
        },
    };

    return (
        <div>
            {phraseDuJour && 
                <HeaderPage leftText={phraseDuJour} centerText={'Statistiques BD'} rightText={''}/>
            }
            <DIVstyled style={{ padding: '20px' }}>
                {stats.map((statBlock, index) => {
                    let chartData;
                    let options = generalOptions;

                    if (['GENRE', 'AUTEUR', 'EDITEUR'].includes(statBlock.TYPESTATS)) { // TYPESTATS = 'GENRE', 'AUTEUR', 'EDITEUR'
                        // Prendre les 10 premiers et cumuler les autres
                        const top10Data = getTop10WithOthers(statBlock.STATS);
                        chartData = createChartData(top10Data, statBlock.TYPESTATS);
                    } else if (statBlock.TYPESTATS === "PARUTION") {    // TYPESTATS = PARUTION
                        // Trier les données par année
                        const sortedData = statBlock.STATS.sort((a, b) => parseInt(a._id) - parseInt(b._id));
                        chartData = createChartData(sortedData, statBlock.TYPESTATS);

                        // Ajuster les options pour un grand nombre de labels
                        options = {
                            ...generalOptions,
                            scales: {
                                x: {
                                    ticks: {
                                        maxRotation: 90,
                                        minRotation: 90,
                                        autoSkip: false, // Assurer que tous les labels sont affichés
                                        color: '#98f58a', // Couleur des textes des abscisses
                                        font: {
                                            size: 12, // Taille de la police (facultatif)
                                            family: 'Arial', // Police d'écriture (facultatif)
                                            weight: 'normal', // Poids de la police (facultatif) normal, bold
                                        }
                                    },
                                    grid: {
                                        display: false,
                                    },
                                },
                                y: {
                                    ticks: {
                                        beginAtZero: true,
                                        color: '#ffcc00', // Couleur des textes des ordonnées
                                        font: {
                                            size: 12, // Taille de la police (facultatif)
                                            family: 'Arial', // Police d'écriture (facultatif)
                                            weight: 'normal', // Poids de la police (facultatif)
                                        }
                                    },
                                },
                            },
                        };                        
                    } else { // TYPESTATS = NOTE
                        chartData = createChartData(statBlock.STATS, statBlock.TYPESTATS);
                        const sortedData = statBlock.STATS.sort((b, a) => parseInt(a._id) - parseInt(b._id));
                        chartData = createChartData(sortedData, statBlock.TYPESTATS);
                    }
                    return (
                        <DIVstyled key={index} style={{ marginBottom: '50px' }}>
                            <H2styled>{labelForTypeStats(statBlock.TYPESTATS)}</H2styled>
                            <Bar data={chartData} options={options} />
                        </DIVstyled>
                    );
                })}
            </DIVstyled>
        </div>
    );
}

export default Stats;