import { createContext, useState, useEffect } from "react";
// import Cookies from 'universal-cookie';
import { 
    ENDPOINT_BEDETHEQUE,
    ENDPOINT_LASTENTRIES,
    ENDPOINT_WISHLIST,
    ENDPOINT_AUTEURS,
    // API_BEDETHEQUEMIGRATION_GET,
    ENDPOINT_STATS,
    myConsoleLog
} from '../../libs/constantes'

// const cookies = new Cookies();

// Contexte BEDETHEQUE (bedetheque, lastentries, wishlist, stats)

const BedethequeContext = createContext()

const BedethequeContextProvider = ({ children }) => {
    /* Pour lire et ecrire dans un cookie */
    // const [searchMode, setSearchMode] = useState(cookies.get('editionMode') === 'true' ? true : false)
    // cookies.set('editionMode', JSON.stringify(mode), {path: '/', maxAge: 3600});

    // const [oldBedetheque, setOldBedetheque] = useState(null)
    const [bedetheque, setBedetheque] = useState([])
    const [bedethequeAndWishlistBySerie, setBedethequeAndWishlistBySerie] = useState([])
    const [bedethequeBySerie, setBedethequeBySerie] = useState([])
    const [lastentries, setLastentries] = useState([])    
    const [wishlist, setWishlist] = useState([])
    const [stats, setStats] = useState(null)
    const [genres, setGenres] = useState(null)
    const [auteurs, setAuteurs] = useState(null)
    const [reload, setReload] = useState(null)

    function setBedethequeFiltred(bds) {
        setBedethequeBySerie(
            bds.filter(
                (obj, index) =>
                bds.findIndex(
                    (item) => item.idserie === obj.idserie
                )
                === index
            )
        )
    }
    
    function compareAuteurs( a, b ) {
        if ( a.nom < b.nom ){
          return -1;
        }
        if ( a.nom > b.nom ){
          return 1;
        }
        return 0;
    }

    function compareGenres( a, b ) {
        if ( a < b ){
          return -1;
        }
        if ( a > b ){
          return 1;
        }
        return 0;
    }

    useEffect(() => {        
        async function fetchStats() {
            let data = "fetchStats... pas de reponse"
            await fetch(ENDPOINT_STATS)
                .then((response) => response.json())
                .then((response) => { myConsoleLog('BUILD_GET_STATS', response); data = response; })
                .catch((error) => { console.warn("BUILD_GET_STATS", error)})
                .finally(() => { setStats(data) })
        };
        
        async function fetchBedetheque() {
            let data = []
            let listeG = []
            await fetch(ENDPOINT_BEDETHEQUE, {
                cache: "no-store",
                headers: {            
                    'cache-control': 'no-cache',
                    'pragma': 'no-cache',
                }
            })
                .then((response) => response.json())
                .then((response) => { myConsoleLog('BEDETHEQUE_BIGALBUMS/', response); data = response; })
                .catch((error) => { console.warn("BEDETHEQUE_BIGALBUMS/", error)})
                .finally(() => {
                    setBedetheque(data)
                    setBedethequeFiltred(data)
                    data.forEach((e) => {
                        if(e.genre instanceof Array) {
                            listeG = [...listeG, ...e.genre]                    
                        }else {
                            if(listeG.indexOf(e.genre) < 0)
                                listeG = [...listeG, e.genre.trim()]
                        }
                    })
                    const listeGenreFiltree = listeG.filter(
                        (item, index) => listeG.indexOf(item) === index)
                    setGenres(listeGenreFiltree.sort(compareGenres))
                    myConsoleLog('GENRES_CTXT', listeGenreFiltree)
                })
        }

        async function fetchLastEntries() {
            let data = []
            await fetch(ENDPOINT_LASTENTRIES, {
                cache: "no-store",
                headers: {            
                    'cache-control': 'no-cache',
                    'pragma': 'no-cache',
                }
            })
                .then((response) => response.json())
                .then((response) => { myConsoleLog('LASTENTRIES_BIGALBUMS/', response); data = response; })
                .catch((error) => { console.warn("LASTENTRIES_BIGALBUMS/", error)})
                .finally(() => {
                    setLastentries(data)
                })
        };

        async function fetchWishlist() {
            let data = []
            await fetch(ENDPOINT_WISHLIST, {
                cache: "no-store",
                headers: {            
                    'cache-control': 'no-cache',
                    'pragma': 'no-cache',
                }
            })
                .then((response) => response.json())
                .then((response) => { myConsoleLog('WISHLIST_BIGALBUMS/', response); data = response; })
                .catch((error) => { console.warn("WISHLIST_BIGALBUMS/", error)})
                .finally(() => {
                    setWishlist(data)
                })
        };

        async function fetchAuteurs() {
            let data = []
            await fetch(ENDPOINT_AUTEURS, {
                cache: "no-store",
                headers: {            
                    'cache-control': 'no-cache',
                    'pragma': 'no-cache',
                }
            })
            .then((response) => response.json())
            .then((response) => { data = response; })
            .catch((error) => { console.warn("BEDETHEQUE_AUTEURS/", error)})
            .finally(() => {
                setAuteurs(data.sort(compareAuteurs))
                myConsoleLog('BEDETHEQUE_AUTEURS/', data);
            })
        }

        // async function fetchBedethequeMigration() {
        //     // fonction de tri pour créer les albums dans mongodb dans l'ordre des tomes
        //     let data = []
        //     await fetch(API_BEDETHEQUEMIGRATION_GET)
        //         .then((response) => response.json())
        //         .then(({bedetheque}) => { data = bedetheque })
        //         .catch((error) => { console.warn(API_BEDETHEQUEMIGRATION_GET + " : An error occured")})
        //         .finally(() => {
        //             let dataSorted = []
        //             data.forEach(d => {
        //                 for (let index = 0; index < d.length; index++) {
        //                     const alb = d[index];
        //                     dataSorted.push(alb)
        //                 }
        //                 dataSorted.sort(function(a, b) {
        //                     var keyA = a.idalbum;
        //                     var keyB = b.idalbum;
        //                     if (keyA < keyB) return -1;
        //                     if (keyA > keyB) return 1;
        //                     return 0;
        //                   });
                        
        //             })
        //             myConsoleLog('bedethequemigration sorted', dataSorted)
        //             setOldBedetheque(dataSorted)
        //         })
        // };

        if(reload || reload === null) {
            fetchStats()
            //fetchBedethequeMigration()
            fetchBedetheque()
            fetchLastEntries()
            fetchWishlist()
            fetchAuteurs()
            setReload(false)
        }
        

    }, [reload]);

    useEffect(() => {
        const bedethequeAndWishlist = [...bedetheque, ...wishlist].sort((a, b) => 
            {
                const serieA = a.serie.toUpperCase();
                const serieB = b.serie.toUpperCase();
                if (serieA < serieB) return -1;
                if (serieA > serieB) return 1;                
                return 0; // names must be equal
            })
        const bddata = bedethequeAndWishlist.filter((c,i,ar) => i === 0 || ar[i].serie !== ar[i-1].serie).map((item) => ({ ...item, value: item.serie }));
        setBedethequeAndWishlistBySerie(bddata)        

    }, [bedetheque, wishlist])

    return (
        <BedethequeContext.Provider value={{ stats, bedetheque, bedethequeAndWishlistBySerie, bedethequeBySerie, lastentries, wishlist, genres, auteurs, setReload }}>
            {children}
        </BedethequeContext.Provider>
    );
};

export { BedethequeContext, BedethequeContextProvider };