import React, { useState, useEffect, useContext } from 'react';
import { CgProfile } from 'react-icons/cg'
import { useNavigate, useLocation } from 'react-router-dom'
import { Menu, Button, Group, Modal, Burger, MantineProvider, PasswordInput, Text } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { BedethequeContext } from '../utils/context/bedetheque'
import { useAuthState, useAuthDispatch, doLogout, doLogin, setUserPrefs } from '../utils/context/auth';
import { ENDPOINT_REFRESHTOKEN } from '../libs/constantes'
import Cookies from 'universal-cookie';
import colors from '../utils/style/colors';
import '../utils/style/modalLogin.css'
import '../utils/style/topMenu.css'

import { jwtDecode } from 'jwt-decode';

const cookies = new Cookies();
const lockRoutes = ['/grab', '/stats', '/dedicaces', '/tomefinder'];            

async function refreshTokenIfNeeded(loggedUser, setUser, dispatch) {
    if (!loggedUser || !loggedUser.token) return;
    
    const token = loggedUser?.token;
    
    if (isTokenExpired(token)) {
        const refreshToken = loggedUser.refreshToken;
        const response = await fetch(ENDPOINT_REFRESHTOKEN, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: refreshToken }),
        });

        if (response.ok) {
            const data = await response.json();
            const updatedUser = { ...loggedUser, token: data.token };
            setUser(updatedUser);
            cookies.set('user', updatedUser);  // Mets à jour le cookie avec le nouveau token
            return true;
        } else {
            console.error('Échec du rafraîchissement du token');
            doLogout(dispatch);
            return false;
        }
    }
    return true;
}
  
function isTokenExpired(token) {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime;
}

function TopMenu() {    
    const { bedetheque } = useContext(BedethequeContext);
    const { user: loggedUser, status, error, isCookie, userPrefs } = useAuthState();
    const [editionMode, setEditionMode] = useState(userPrefs?.editionMode);
    const dispatch = useAuthDispatch();
    const [user, setUser] = useState("");
    const [modalOpened, setModalOpened] = useState(false);
    const [modalErrorMsg, setModalErrorMsg] = useState(401);

    const [burgerOpened, setBurgerOpened] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const locPathname = () => {
        if(location.pathname.includes('/serie')) {
            let tSerie = location.pathname.split('/')[2]
            if(!!tSerie) {
                let serieFromCtxt = bedetheque.filter((el) => el.idserie === parseInt(tSerie))
                if(serieFromCtxt.length) { 
                    return '- '+serieFromCtxt[0].serie
                }
            } else {
                return '/'
            }
        } else {
            return location.pathname
        }
    }

    document.title = `ReactBD ${locPathname()}`;

    useEffect(() => {
        if(loggedUser) {            
            setModalOpened(false)
            if(!isCookie) {
                showNotification({
                    title: '🔓 Connexion réussie',
                    message: `Bienvenue ${loggedUser.name}`,
                    styles: (theme) => ({
                        root: {
                          backgroundColor: theme.colors.blue[6],
                          borderColor: theme.colors.blue[6],
                          '&::before': { backgroundColor: theme.white },
                        },
                        title: { color: theme.white },
                        description: { color: theme.white },
                        closeButton: {
                          color: theme.white,
                          '&:hover': { backgroundColor: theme.white },
                        },
                      }),
                })
            }            
        }

        if(!loggedUser) {
            editionMode && setEditionMode(false)            
            if(lockRoutes.indexOf(location.pathname) > -1)
                navigate('/')
        } 
    }, [loggedUser])

    useEffect(() => { if (status === 'rejected') setModalErrorMsg(401) }, [status])
    useEffect(() => { if (modalOpened) setModalErrorMsg(null) }, [modalOpened])
    
    useEffect(() => {
        if(loggedUser) {
            userPrefs.editionMode = editionMode;
            setUserPrefs(dispatch, userPrefs)
        }
    }, [editionMode])

    async function navTo(route) {
        if(lockRoutes.indexOf(route) > -1) {
            const tokenRefreshed = await refreshTokenIfNeeded(loggedUser, (updatedUser) => {
                if (updatedUser) {
                  dispatch({
                    type: 'UPDATE_USER',
                    payload: updatedUser,
                  });
                }
              }, dispatch);
            
              if(tokenRefreshed) {
                navigate(route);
              } else {
                console.error('Impossible de naviguer car le token n\'a pas pu être rafraîchi.');
              }
        } else {
            navigate(route);
        }
    }        

    const handleChange = (e) => {
        setUser(e.target.value)
    };

    const handleKeyPress = (e) => {
        if(e.key === 'Enter' && user?.length > 3){
            handleChange(e)
            doLogin(dispatch, user);
            setUser("");
        }
    };    

    return (
        <>
            <Modal
                style={{ zIndex: '900', marginTop : '150px'}}
                size='xs'
                withCloseButton={false}
                opened={modalOpened}
                onClose={() => setModalOpened(false)}
            >
                <div className='modalLogin'>
                    <div className='leftModal'>
                        <PasswordInput
                            placeholder="Password"
                            onChange={handleChange}
                            withAsterisk
                            onKeyDown={handleKeyPress}
                        />
                    </div>
                    <div className='rightModal'>
                        {status === "rejected" && error && (
                            <span style={{color: 'red'}}>{modalErrorMsg}</span>
                        )}
                    </div>                    
                </div>
            </Modal>

            <div className='navBarContainer'>
                
                <div className='navBarLeft'>                    
                </div>

                <div className='navBarCenter'>
                    <nav>
                        <Group position="center">
                            <MantineProvider inherit theme={{ defaultGradient: { from: 'orange', to: 'yellow', deg: 45, }, }} >
                                <Button size={'xs'} variant="gradient" onClick={() => navTo("/")}>&nbsp;ACCUEIL&nbsp;</Button>
                            </MantineProvider>

                            <Menu transition="rotate-left" transitionDuration={150} shadow="xs" width={280} onChange={(opened) => {setBurgerOpened(opened)}}>
                                <Menu.Target>
                                    <Burger
                                        opened={burgerOpened}
                                        onClick={() => setBurgerOpened((o) => !o)}                                
                                    />
                                </Menu.Target>

                                <Menu.Dropdown>
                                    <Menu.Label>
                                        {loggedUser && (
                                            <>Welcome {loggedUser.name}</>
                                        )}
                                        {!loggedUser && (
                                            <>Mode invité</>
                                        )
                                        }
                                    </Menu.Label>
                                    <Menu.Item variant="big" onClick={() => navTo("/search")}><Text fz="xl" fw={700}>🔍 RECHERCHE</Text></Menu.Item>
                                    <Menu.Item onClick={() => navTo("/wishlist")}><Text fz="xl" fw={700}>💲 WISHLIST</Text></Menu.Item>
                                    { loggedUser && 
                                    <Menu.Item onClick={() => navTo("/dedicaces")}><Text fz="xl" fw={700}>🔖 DEDICACES</Text></Menu.Item>
                                    }
                                    <Menu.Divider />
                                    <Menu.Label>Admin zone</Menu.Label> 
                                    { loggedUser && 
                                        <>
                                        <Menu.Item onClick={() => navTo("/grab")}><Text fz="xl" fw={700}>🔄️ GRAB ALBUM</Text></Menu.Item>
                                        <Menu.Item onClick={() => navTo("/tomefinder")}><Text fz="xl" fw={700}>🐍 TOME FINDER</Text></Menu.Item>
                                        <Menu.Item onClick={() => navTo("/stats")}><Text fz="xl" fw={700}>📅 STATISTIQUES</Text></Menu.Item>
                                        {/* <Menu.Item onClick={() => navTo("/mig")}><Text fz="xl" fw={700}>🥼 MIGRATION</Text></Menu.Item> */}
                                        </>
                                    }                                    
                                    { loggedUser && editionMode && 
                                        <Menu.Item onClick={() => {
                                            setEditionMode(false)
                                        }}><Text fz="xl" fw={700}>⏺️ EDITION</Text></Menu.Item>
                                    }
                                    { loggedUser && !editionMode && 
                                        <Menu.Item onClick={() => {
                                            setEditionMode(true)
                                        }}><Text fz="xl" fw={700}>▶️ CONSULTATION</Text></Menu.Item>
                                    } 
                                    { loggedUser && 
                                        <Menu.Item color="blue" onClick={() => { 
                                            doLogout(dispatch);
                                        }}><Text fz="xl" fw={700}>🔓 LOGOFF</Text></Menu.Item>
                                    }
                                    { !loggedUser &&
                                        <Menu.Item onClick={() => setModalOpened(true)}><Text fz="xl" fw={700}>🔒 LOGIN</Text></Menu.Item>
                                    }
                                    
                                </Menu.Dropdown>
                            </Menu>                                                        

                            <MantineProvider inherit theme={{ defaultGradient: {from: 'yellow', to: 'orange', deg: 45, }, colors: { 'ocean-blue': colors['ocean-blue'], 'bright-pink': colors['bright-pink'], }, }} >
                                <Button size={'xs'} variant="gradient" onClick={() => navTo("/bedetheque")}>BDTHEQUE</Button>                                
                            </MantineProvider>
                        
                        </Group>
                    </nav>
                </div>
                <div className='navBarRight'>         
                    {loggedUser &&
                        <CgProfile size={25} color={ editionMode ? 'crimson' : 'rgb(39, 36, 36)'} onClick={() => { setEditionMode(!editionMode) }} style={{cursor: 'pointer'}}/>
                    }
                </div>
            </div>
            
        </>
    )
}

export default TopMenu
