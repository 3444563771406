/* Pour la migration si besoin
    l api rest php n'est plus sous comics.byrod.fr, refaire un CNAME si besoin
*/
const COMICS_API_URL = "nouvelleUrlAdefinir/restservices/"
export const API_BEDETHEQUEMIGRATION_GET     = COMICS_API_URL + "bedethequemigration.php"

const SERVER_API_URL = process.env.REACT_APP_NODE_URI || "http://localhost:5000/"

export const ENDPOINT_BEDETHEQUE = SERVER_API_URL + "bedetheque/"
export const ENDPOINT_LASTENTRIES = SERVER_API_URL + "lastentries/"
export const ENDPOINT_WISHLIST = SERVER_API_URL + "wishlist/"
export const ENDPOINT_ALBUM = SERVER_API_URL + "album/"
export const ENDPOINT_SERIE = SERVER_API_URL + "serie/"
export const ENDPOINT_AUTEUR = SERVER_API_URL + "auteur/"
export const ENDPOINT_AUTEURS = SERVER_API_URL + "auteurs/"
export const ENDPOINT_LOGIN = SERVER_API_URL + "login/"
export const ENDPOINT_SEARCH = SERVER_API_URL + "search/"
export const ENDPOINT_GRAB = SERVER_API_URL + "grab/"
export const ENDPOINT_STATS = SERVER_API_URL + "stats/"
export const ENDPOINT_COUVERTURE = SERVER_API_URL + "couverture/"
export const ENDPOINT_DEDICACES = SERVER_API_URL + "dedicaces/"
export const ENDPOINT_TOMEFINDER = SERVER_API_URL + "tomefinder/"
export const ENDPOINT_REFRESHTOKEN = SERVER_API_URL + "refresh-token/"

export const COUVERTURES_DIRECTORY = SERVER_API_URL + "couv/"
export const DEDICACES_DIRECTORY = SERVER_API_URL + "dedi/"

export const STR_LNK_SEP = '#️⃣';

export function myConsoleLog(a,b=null) {    
    if(process.env.REACT_APP_DEBUG_MODE) {
        if(a && b) console.log(a, b)
        if(a && !b) console.log(a)
    }
}