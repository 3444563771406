import { remove } from 'remove-accents'
import { myConsoleLog, ENDPOINT_ALBUM, ENDPOINT_AUTEUR, ENDPOINT_SERIE, ENDPOINT_GRAB, COUVERTURES_DIRECTORY, DEDICACES_DIRECTORY, ENDPOINT_COUVERTURE, ENDPOINT_TOMEFINDER } from './constantes'

export const writeIgnorelist = async (item, token) => {
    if(item?._id) {
        fetch(ENDPOINT_TOMEFINDER + item._id, {
            method: 'PATCH',
            body: JSON.stringify(item),
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
        .then((response)    => response.json())
        .then((response)    => { myConsoleLog('ENDPOINT_TOMEFINDER', response) })
        .catch((error)      => { console.warn('ENDPOINT_TOMEFINDER', error) })
    }
}

function formatSerieAndTome(album, dedicace = false) {
    const serieWithoutAccent = remove(album.serie)
    const serieLowercase = serieWithoutAccent.toLowerCase()
    const isTomePresent = album.tome ? album.tome?.toLowerCase() : ''
    const albumLowercase = `${serieLowercase}_${isTomePresent}.jpg`
    return dedicace ? `${albumLowercase}` : `${serieLowercase}/${albumLowercase}`
}

export const formatCouverturePath = (album) => {
    return `${COUVERTURES_DIRECTORY}${formatSerieAndTome(album)}`
}

export const formatDedicacePath = (album,) => {
    return `${DEDICACES_DIRECTORY}${formatSerieAndTome(album, true)}`
}

export function handleAnchor (hash) {
    window.location.href = `#${hash}`
    setTimeout(() => {
        window.scrollBy({
            top: -150,
            behavior: 'smooth'
          });
      }, "100")
}

// @DOC-BYROD shallowEqual
export function shallowEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (let key of keys1) {
        if (object1[key] !== object2[key]) {
            return false;
        }
    }
    return true;
}

export async function updateBigAlbum(id, body, token) {
    fetch(ENDPOINT_ALBUM + id, {        
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: {            
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
    .then((response)    => response.json())
    .then((response)    => { myConsoleLog('UPDATE_BIGALBUM', response) })
    .catch((error)      => { console.warn('UPDATE_BIGALBUM', error) })
};

export async function deleteAlbum(id, body, token) {
    fetch(ENDPOINT_ALBUM + id, {
        method: 'DELETE',
        body: JSON.stringify(body),
        headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
    .then((response)    => response.json())
    .then((response)    => { myConsoleLog('DELETE_BIGALBUM', response) })
    .catch((error)      => { console.warn('DELETE_BIGALBUM', error) })
};

export async function createAlbumInBedetheque(jsonBody, token) {
    fetch(ENDPOINT_ALBUM, {
        method: 'POST',
        body: JSON.stringify(jsonBody),
        cache: "no-store",
        headers: {
            'cache-control': 'no-cache',
            'pragma': 'no-cache',
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
    .then((response)    => response.json())
    .then((response)    => { myConsoleLog('CREATE_BIGALBUM', response) })
    .catch((error)      => { console.warn('CREATE_BIGALBUM', error) })    
};

export async function updateSerie(id, body, token) {
    fetch(ENDPOINT_SERIE + id, {
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
    .then((response)    => response.json())
    .then((response)    => { myConsoleLog('UPDATE_SERIE', response) })
    .catch((error)      => { console.warn('UPDATE_SERIE', error) })
};

export async function updateAuteur(id, body, token) {
    fetch(ENDPOINT_AUTEUR + id, {
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
    .then((response)    => response.json())
    .then((response)    => { myConsoleLog('UPDATE_AUTEUR', response) })
    .catch((error)      => { console.warn('UPDATE_AUTEUR', error) })
};

export async function grabAlbum(url, token) {
    return await fetch(ENDPOINT_GRAB, {
        method: 'POST',
        body: JSON.stringify({url: url}),
        headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
    .then((response)    => response.json())
    .then((response)    => { 
        myConsoleLog('GRAB_BIGALBUM', response);
        return response;
    })
    .catch((error)      => { console.warn('GRAB_BIGALBUM', error) })
}


export async function updateCouverture(id, url, token) {
    return await fetch(ENDPOINT_COUVERTURE + id, {
        method: 'POST',
        body: JSON.stringify({newCouvUrl: url}),
        headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
    .then((response)    => response.json())
    .then((response)    => { 
        myConsoleLog('UPDATE_COUVERTURE', response);
        return response;
    })
    .catch((error)      => { console.warn('UPDATE_COUVERTURE', error) })
}


