// ElementMaker.js

import React from "react";

// Create an ElementMaker component
function ElementInputMaker(props) {
  return (
    <span>
      {
        // Use JavaScript's ternary operator to specify <span>'s inner content
        props.showInputEle ? (
          <input
            type="text"
            size={props.size}
            defaultValue={props.value}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            autoFocus
          />
        ) : (
          <span
            onDoubleClick={props.handleDoubleClick}
            style={{
              display: "inline-block",
              // height: `${props.height}px`,
              // minWidth: `${props.width}px`,
            }}
          >
            {props.value}
          </span>
        )
      }
    </span>
  );
}

export default ElementInputMaker;